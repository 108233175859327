import { useState, useEffect } from 'react';
import { ImBlocked } from "react-icons/im";
import { JSONPARSESTRIGFY } from '../Utils';
import { createClient } from "@supabase/supabase-js";
import './AdminPage.css';

function AuthorizedUser(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [supabase, setSupabase] = useState();

    useEffect(() => {
        fetchSupabase();
    }, []);

    const fetchSupabase = async () => {
        const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
            .then((res) => res.json())
            .then((finalRes) => {
                return finalRes;
            });
        const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
        setSupabase(supabaseTemp);
    }

    useEffect(() => {
        if (supabase === undefined) return;
        fetchUserData();
    }, [supabase]);


    const unAuthorize = async () => {
        const { error } = await supabase.from("Users_Authorized").update({ authorized: false }).eq('uid', props.uid);
        if (error) {
            console.error(JSONPARSESTRIGFY(error).message);
        }
        props.reload();
    }

    const fetchUserData = async () => {
        const { data: firstNameData, error: firstNameError } = await supabase.from("Users_FirstName").select().eq('uid', props.uid);
        if (firstNameError) {
            console.error(JSONPARSESTRIGFY(firstNameError).message);
        }
        if (firstNameData) {
            if (firstNameData.length > 0) {
                setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
            }
        }
        const { data: lastNameData, error: lastNameError } = await supabase.from("Users_LastName").select().eq('uid', props.uid);
        if (lastNameError) {
            console.error(JSONPARSESTRIGFY(lastNameError).message);
        }
        if (lastNameData) {
            if (lastNameData.length > 0) {
                setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
            }
        }
        const { data: emailData, error: emailError } = await supabase.from("Users_Email").select().eq('uid', props.uid);
        if (emailError) {
            console.error(JSONPARSESTRIGFY(emailError).message);
        }
        if (emailData) {
            if (emailData.length > 0) {
                setEmail(JSONPARSESTRIGFY(emailData[0]).email);
            }
        }
    }

    return (
        <>
            {firstName.toLocaleLowerCase().includes(props.filter.toLocaleLowerCase()) &&
                <div className="authorizedUser" >
                    <p>First name: {firstName}</p>
                    <p>Last name: {lastName}</p>
                    <p>Email: {email}</p>
                    <p>UID: {props.uid}</p>
                    <ImBlocked
                        style={{ marginTop: "1rem" }}
                        className="BsTrash"
                        onClick={() => unAuthorize()}
                    />
                </div>}
        </>
    )
}

export default AuthorizedUser;
