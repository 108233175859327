import { useState, useEffect, useCallback } from "react";
import BugReport from "../Home/settings/bugReport";

export const BugReportAppComponent = (props) => {
  const [isBugReportComponentOpen, setBugComponentOpen] = useState(false);

  const onBugComponentComponentClose = useCallback(() => {
    props.setSelectedComponent("");
    setBugComponentOpen(false);
  }, [isBugReportComponentOpen]);

  const onStudentComponentOpen = () => {
    setBugComponentOpen(true);
  };

  // Open the popup when the app component mounts
  useEffect(() => {
    onStudentComponentOpen();
  }, []);

  return (
    <div>
      {isBugReportComponentOpen && (
        <BugReport close={() => onBugComponentComponentClose()} />
      )}
    </div>
  );
}