import { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import { AiOutlineEdit, AiOutlineEnter } from "react-icons/ai";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import "./Class.css";
import { JSONPARSESTRIGFY } from "../Utils";

type reactopnProps = {
  uid: string;
  stateProp: string;
  name: string;
  onEnter: (className: string) => void;
};

const ClassComponent = ({
  uid,
  stateProp,
  name,
  onEnter,
}: reactopnProps) => {
  const [classroom, setClassroom] = useState(name);
  const [state, setState] = useState(stateProp);
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
      fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
      const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
          .then((res) => res.json())
          .then((finalRes) => {
              return finalRes;
          });
      const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
      setSupabase(supabaseTemp);
  }

  // useEffect(() => {
  //     if (supabase === undefined) return;
  // }, [supabase]);

  const submit = async () => {
    if (classroom == "") return;
    const { data: fetchData, error: fetchError } = await supabase
      .from("Classes")
      .select()
      .eq("name", classroom)
      .eq("teacherUID", uid);
    if (fetchData && fetchData?.toString().length > 0) {
      alert("name is allready in use");
      return;
    }
    const { data: insertData, error: insertError } = await supabase
      .from("Classes")
      .insert({ name: classroom, teacherUID: uid });
    if (insertData) {
      console.log(JSON.stringify(insertData));
    }
    if (insertError) {
      console.error("error saving data to DB " + JSONPARSESTRIGFY(insertError).message);
    }
    setState("show");
  };

  return (
    <>
      {state === "input" ? (
        <div className="classInput">
          <input
            type="text"
            placeholder="Enter class name..."
            value={classroom}
            onChange={(e) => setClassroom(e.target.value)}
          />
          <button className="appButton" onClick={() => submit()}>Submit</button>
        </div>
      ) : (
        <div className="appButton" onClick={() => onEnter(classroom)}>
          <p>Class name: {classroom}</p>
        </div>
      )}
    </>
  );
};

export default ClassComponent;
