import { FiLogOut } from "react-icons/fi";
import { TfiSave } from "react-icons/tfi";
import { MdOutlineLockReset } from "react-icons/md";
import { useState, useEffect } from "react";
import { JSONPARSESTRIGFY } from "../../Utils";
import { createClient } from "@supabase/supabase-js";
import './settings.css';

function Settings(props) {
    const [uid, setUID] = useState("");
    const [rule, setRule] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [childUID, setChildUID] = useState("");
    const [supabase, setSupabase] = useState();

    useEffect(() => {
        fetchSupabase();
    }, []);

    const fetchSupabase = async () => {
        const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
            .then((res) => res.json())
            .then((finalRes) => {
                return finalRes;
            });
        const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
        setSupabase(supabaseTemp);
    };

    useEffect(() => {
        if (supabase === undefined) return;
        supabase.auth.getSession().then((data) => {
            const session = data.data.session;
            if (session != null) {
                setUID(session.user.id);
                setRule(session.user.user_metadata.rule);
                fetchData(session.user.user_metadata.rule, session.user.id);
            }
        });
    }, [supabase]);

    const fetchData = async (rule, userID) => {
        const { data: firstNameData, error: firstNameError } = await supabase
            .from("Users_FirstName")
            .select()
            .eq("uid", userID);
        if (firstNameError) {
            console.error("error: " + JSONPARSESTRIGFY(firstNameError).message);
        }
        if (firstNameData) {
            if (firstNameData.length > 0) {
                setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
            }
        }
        const { data: lastNameData, error: lastNameError } = await supabase
            .from("Users_LastName")
            .select()
            .eq("uid", userID);
        if (lastNameError) {
            console.error("error: " + JSONPARSESTRIGFY(lastNameError).message);
        }
        if (lastNameData) {
            if (lastNameData.length > 0) {
                setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
            }
        }
        const { data: emailData, error: emailError } = await supabase
            .from("Users_Email")
            .select()
            .eq("uid", userID);
        if (emailError) {
            console.error("error: " + JSONPARSESTRIGFY(emailError).message);
        }
        if (emailData) {
            if (emailData.length > 0) {
                setEmail(JSONPARSESTRIGFY(emailData[0]).email);
            }
        }
        // const { data: schoolData, error: schoolError } = await supabase
        //     .from("Users_School")
        //     .select()
        //     .eq("uid", userID);
        // if (schoolError) {
        //     console.error("error: " + JSONPARSESTRIGFY(schoolError).message);
        // }
        // if (schoolData) {
        //     if (schoolData.length > 0) {
        //         setUserSchool(JSONPARSESTRIGFY(schoolData[0]).school);
        //     }
        // }
        // const { data: classData, error: classError } = await supabase
        //     .from("Users_Class")
        //     .select()
        //     .eq("uid", userID);
        // if (classError) {
        //     console.error("error: " + JSONPARSESTRIGFY(classError).message);
        // }
        // if (classData) {
        //     if (classData.length > 0) {
        //         if (JSONPARSESTRIGFY(classData[0]).class !== null) {
        //             setUserClass(JSONPARSESTRIGFY(classData[0]).class);
        //         }
        //     }
        // }
        if (rule === "Parent") {
            const { data: studentUIDData, error: studentUIDError } = await supabase
                .from("Parents_ChildUID")
                .select()
                .eq("uid", userID);
            if (studentUIDError) {
                console.error("error: " + JSONPARSESTRIGFY(studentUIDError).message);
            }
            if (studentUIDData) {
                if (studentUIDData.length > 0) {
                    if (JSONPARSESTRIGFY(studentUIDData[0]).childUID != undefined) {
                        setChildUID(JSONPARSESTRIGFY(studentUIDData[0]).childUID);
                    }
                }
            }
        }
    };

    const copyUIDToClipBoard = (userID) => {
        navigator.clipboard.writeText(userID);
        props.setErrorMessage("Child user ID Copied");
    }

    const save = async () => {
        const { error: firstNameError } = await supabase
            .from('Users_FirstName')
            .update({ firstName: firstName })
            .eq('uid', uid);
        if (firstNameError) {
            props.setErrorMessage("Error updating admin first name " + JSONPARSESTRIGFY(firstNameError).message);
            console.error("Error updating admin first name " + JSONPARSESTRIGFY(firstNameError).message);
        } else {
            props.setErrorMessage("Data saved.");
        }
        const { error: lastNameError } = await supabase
            .from('Users_LastName')
            .update({ lastName: lastName })
            .eq('uid', uid);
        if (lastNameError) {
            props.setErrorMessage("Error updating admin last name " + JSONPARSESTRIGFY(lastNameError).message);
            console.error("Error updating admin last name " + JSONPARSESTRIGFY(lastNameError).message);
        } else {
            props.setErrorMessage("Data saved.");
        }
        const { error } = await supabase
            .from('Parents_ChildUID')
            .update({ childUID: childUID })
            .eq('uid', uid);
        if (error) {
            props.setErrorMessage("Error updating admin last name " + JSONPARSESTRIGFY(error).message);
            console.error("Error updating admin last name " + JSONPARSESTRIGFY(error).message);
        } else {
            props.setErrorMessage("Data saved.");
        }
    }

    const sendPasswordReset = async () => {
        const { data, error } = await supabase.auth.resetPasswordForEmail(
            email,
            { redirectTo: 'localhost:3000' }
        )
        alert("Password recorvery email sent...");
    }

    return (
        <div id="settingsGRAB" className='settings'>
            <div className='settingsHeader'>
                <h3>User settings</h3>
                <button className='closeButton appButton' onClick={() => props.close()}>X</button>
            </div>
            <div className="inputContainer">
                <label htmlFor="firstName">Your first name: </label>
                <input id="firstName" type='text' placeholder='Enter your first name...' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className='inputContainer'>
                <label htmlFor="lastName">Your last name: </label>
                <input id="lastName" type='text' placeholder='Enter your last name...' value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            {rule === "Parent" &&
                <div className='inputContainer'>
                    <label htmlFor="childUID">Your child UID: </label>
                    <input id="childUID" type='text' placeholder='Enter your child user ID...' value={childUID} onChange={(e) => setChildUID(e.target.value)} />
                </div>
            }
            {props.errorMessage && <p>{props.errorMessage}</p>}
            {rule === "Parent" &&
                <div className="appButton logoutButtonContainer" onClick={() => copyUIDToClipBoard(childUID)}>
                    <p>Copy child UID</p>
                    <TfiSave className="FiLogOut" />
                </div>
            }
            <div className="appButton logoutButtonContainer" onClick={() => save()}>
                <p>Save</p>
                <TfiSave className="FiLogOut" />
            </div>
            <div className="appButton logoutButtonContainer" onClick={() => sendPasswordReset()}>
                <p>Reset password</p>
                <MdOutlineLockReset className="FiLogOut" />
            </div>
            <div className="appButton logoutButtonContainer" onClick={() => props.signOut()}>
                <p>Logout</p>
                <FiLogOut className="FiLogOut" />
            </div>
        </div>
    )
}

export default Settings;