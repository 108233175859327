import { useEffect, useState } from "react";
import { JSONPARSESTRIGFY } from "../Utils";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import "./TeacherComponent.css";

type reactopnProps = {
  uid: string;
  filter: string;
  onEnter: (teacher: object, type: string) => void;
};

const TeacherComponent = ({ uid, onEnter, filter }: reactopnProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [classroom, setClassroom] = useState("");
  const [loading, setLoading] = useState(true);
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    fetchUserData();
  }, [supabase]);

  const fetchUserData = async () => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", uid);
    if (firstNameError) {
      console.error(JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData) {
      if (firstNameData.length > 0) {
        setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", uid);
    if (lastNameError) {
      console.error(JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData) {
      if (lastNameData.length > 0) {
        setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
      }
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", uid);
    if (emailError) {
      console.error(JSONPARSESTRIGFY(emailError).message);
    }
    if (emailData) {
      if (emailData.length > 0) {
        setEmail(JSONPARSESTRIGFY(emailData[0]).email);
      }
    }
    const { data: ClassRoomData, error: ClassRoomError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", uid);
    if (ClassRoomError) {
      console.error(JSONPARSESTRIGFY(ClassRoomError).message);
    }
    if (ClassRoomData) {
      if (
        JSONPARSESTRIGFY(ClassRoomData[0]).class !== null &&
        JSONPARSESTRIGFY(ClassRoomData[0]).class.length > 0
      ) {
        setClassroom(JSONPARSESTRIGFY(ClassRoomData[0]).class);
      }
    }
    const { data: SchoolData, error: SchoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", uid);
    if (SchoolError) {
      console.error(JSONPARSESTRIGFY(SchoolError).message);
    }
    if (SchoolData) {
      if (JSONPARSESTRIGFY(SchoolData[0]).school !== null) {
        setSchool(JSONPARSESTRIGFY(SchoolData[0]).school);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <button className="appButton">Loading...</button>
      ) : (
        <>
          {firstName
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) && (
            <div>
              {/* <p>{firstName}</p>
      <p>{lastName}</p>
      <p>{uid}</p>
      <p>{email}</p> */}
              <button
                className="appButton"
                onClick={() =>
                  onEnter(
                    {
                      firstName: firstName,
                      lastName: lastName,
                      uid: uid,
                      email: email,
                      school: school,
                      classroom: classroom,
                    },
                    "Teacher",
                  )
                }
              >
                {firstName}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TeacherComponent;
