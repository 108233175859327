import "./ExercisesList.css";
import { GiCheckMark } from "react-icons/gi";
import { useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  defaultLang,
  getLanguage,
  languages,
  setLanguage,
  t as tLayerUI,
} from "../../../i18n";
import CalcSad from "../../calcSad.mp4";
import CalcHappy from "../../calcHappy.mp4";
import { JSONPARSESTRIGFY } from "../../Utils";

type reactopnProps = {
  exercises: object[];
  exercisesCompleted: object[];
  homework: object[];
  homeworkCompleted: object[];
  assigments: object[];
  assigmentsCompleted: object[];
  exams: object[];
  examsCompleted: object[];
  updateCurrentExercise: (data: object) => void;
  onClose: () => void;
};

const ExercisesList = ({
  exercises,
  exercisesCompleted,
  homework,
  homeworkCompleted,
  assigments,
  assigmentsCompleted,
  exams,
  examsCompleted,
  updateCurrentExercise,
  onClose,
}: reactopnProps) => {
  const [selection, setSelection] = useState("select");
  const [exercisesTemp, setExercisesTemp] = useState([]);

  const updateCurrentExerciseAndCloseWindow = (data: object) => {
    updateCurrentExercise(data);
    onClose();
  };

  const switchExercisesTemp = (exercisesParam: any) => {
    setSelection("exercisesTemp")
    setExercisesTemp(exercisesParam);
  };

  return (
    <div className="coolBG">
      <div className="chatHeader">
        {selection !== "select" ? (
          <button
            className="appButton"
            style={{zIndex: "9999"}}
            onClick={() => setSelection("select")}
          >
            <IoArrowBackOutline />
          </button>
        ) : (
          <button className="specialCloseChatButton" onClick={onClose}>
            X
          </button>
        )}
        {selection === "select" ? (
          <p>Choose your category!</p>
        ) : selection === "homework" ? (
          <p>Choose your homework!</p>
        ) : selection === "exams" ? (
          <p>Choose your exam!</p>
        ) : selection === "assigments" ? (
          <p>Choose your assigment!</p>
        ) : selection === "exercises" ? (
          <p>Choose your exercise!</p>
        ) : null}
        {/* <video
        autoPlay
        loop
        muted
        playsInline
        className="mascot"
        src={CalcHappy}
      /> */}
      </div>
      <div className="coolBGeffect">
        {selection === "select" ? (
          <div className="exercisesList">
            <div
              className="appButton"
              onClick={() => setSelection("exercises")}
              style={{ padding: ".3rem", margin: ".2rem" }}
            >
              <p>exercises</p>
            </div>
            <div
              className="appButton"
              onClick={() => setSelection("exams")}
              style={{ padding: ".3rem", margin: ".2rem" }}
            >
              <p>exams</p>
            </div>
            <div
              className="appButton"
              onClick={() => setSelection("assigments")}
              style={{ padding: ".3rem", margin: ".2rem" }}
            >
              <p>assigments</p>
            </div>
            <div
              className="appButton"
              onClick={() => setSelection("homework")}
              style={{ padding: ".3rem", margin: ".2rem" }}
            >
              <p>homework</p>
            </div>
          </div>
        ) : selection === "exercises" ? (
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Exercise list</h2>
            <div className="exercises">
            {exercisesCompleted ? (
                exercisesCompleted.map((data, index) => (
                  <div
                    className="appButton checkMarkButton"
                    key={index}
                    onClick={() => updateCurrentExerciseAndCloseWindow(JSONPARSESTRIGFY(data))}
                    style={{ padding: ".3rem", margin: ".2rem" }}
                  >
                    <GiCheckMark />
                    <p>{JSONPARSESTRIGFY(data).question}</p>
                  </div>
                ))
              ) : (
                <p>error getting exercises</p>
              )}
              {exercises ? (
                exercises.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => updateCurrentExerciseAndCloseWindow(JSONPARSESTRIGFY(data))}
                  >
                    {JSONPARSESTRIGFY(data).question}
                  </button>
                ))
              ) : (
                <p>error getting homework</p>
              )}
            </div>
          </div>
        ) : selection === "homework" ? (
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Homework list</h2>
            <div className="exercises">
              {homeworkCompleted ? (
                homeworkCompleted.map((data, index) => (
                  <div
                    className="appButton checkMarkButton"
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                    style={{ padding: ".3rem", margin: ".2rem" }}
                  >
                    <GiCheckMark />
                    <p>{JSONPARSESTRIGFY(data).subject}</p>
                  </div>
                ))
              ) : (
                <p>error getting exercises</p>
              )}
              {homework ? (
                homework.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                  >
                    {JSONPARSESTRIGFY(data).subject}
                  </button>
                ))
              ) : (
                <p>error getting homework</p>
              )}
            </div>
          </div>
        ) : selection === "assigments" ? (
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Assigments list</h2>
            <div className="exercises">
            {assigmentsCompleted ? (
                assigmentsCompleted.map((data, index) => (
                  <div
                    className="appButton checkMarkButton"
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                    style={{ padding: ".3rem", margin: ".2rem" }}
                  >
                    <GiCheckMark />
                    <p>{JSONPARSESTRIGFY(data).subject}</p>
                  </div>
                ))
              ) : (
                <p>error getting assigments</p>
              )}
              {assigments ? (
                assigments.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                  >
                    {JSONPARSESTRIGFY(data).subject}
                  </button>
                ))
              ) : (
                <p>error getting homework</p>
              )}
            </div>
          </div>
        ) : selection === "exams" ? (
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Exams list</h2>
            <div className="exercises">
            {examsCompleted ? (
                examsCompleted.map((data, index) => (
                  <div
                    className="appButton checkMarkButton"
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                    style={{ padding: ".3rem", margin: ".2rem" }}
                  >
                    <GiCheckMark />
                    <p>{JSONPARSESTRIGFY(data).subject}</p>
                  </div>
                ))
              ) : (
                <p>error getting exercises</p>
              )}
              {exams ? (
                exams.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => switchExercisesTemp(JSONPARSESTRIGFY(data).questions)}
                  >
                    {JSONPARSESTRIGFY(data).subject}
                  </button>
                ))
              ) : (
                <p>error getting exams</p>
              )}
            </div>
          </div>
        ) : selection === "exercisesTemp" ? (
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Exercise list</h2>
            <div className="exercises">
              {/* {exercisesCompleted ? (
                exercisesCompleted.map((data, index) => (
                  <div
                    className="appButton checkMarkButton"
                    key={index}
                    onClick={() => updateCurrentExerciseAndCloseWindow(data)}
                    style={{ padding: ".3rem", margin: ".2rem" }}
                  >
                    <GiCheckMark />
                    <p>{JSONPARSESTRIGFY(data)).question}</p>
                  </div>
                ))
              ) : (
                <p>error getting exercises</p>
              )} */}
              {exercisesTemp ? (
                exercisesTemp.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => updateCurrentExerciseAndCloseWindow(data)}
                  >
                    {JSONPARSESTRIGFY(data).question}
                  </button>
                ))
              ) : (
                <p>error getting exercises</p>
              )}
            </div>
          </div>) : null}
      </div>
    </div>
  );
};

export default ExercisesList;
