import { ToolButton } from "../../components/ToolButton";
import { GPTIcon } from "../../components/icons";
import { KEYS } from "../../keys";
import clsx from "clsx";
import "../../components/ToolIcon.scss";

type LockIconProps = {
  title?: string;
  isMobile?: boolean;
  onClick?: () => void;
};

export const GPTChatButton = (props: LockIconProps) => {
  const showChat = () => {
    if (props.onClick == null) return;
    props.onClick();
  }
  
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="button"
      icon={GPTIcon}
      name="editor-current-shape"
      title={`${props.title}`}
      aria-label={`${props.title}`}
      onClick={() => showChat()}
    />
  );
};