import { useState, useEffect, useCallback } from "react";
import StudentSettings from "../Student/StudentPopUp";

export const StudentAppComponent = (props) => {
  const [isStudentComponentOpen, setStudentComponentOpen] = useState(false);

  const onStudentComponentClose = useCallback(() => {
    props.setSelectedComponent("");
    setStudentComponentOpen(false);
  }, [isStudentComponentOpen]);

  const onSetShowTime = (value) => {
    props.setShowHintTime(value);
  };

  const onStudentComponentOpen = () => {
    setStudentComponentOpen(true);
  };

  // Open the popup when the app component mounts
  useEffect(() => {
    onStudentComponentOpen();
  }, []);

  return (
    <div>
      {isStudentComponentOpen && (
        <StudentSettings
          onClose={onStudentComponentClose}
          onChangeSettings={(value) => onSetShowTime(value)}
        />
      )}
    </div>
  );
}