const notes = [
    {
      key: 1,
      title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
      content:
        "By this and That from top university"
    },
    {
      key: 2,
      title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
      content:
        "By this and That from top university"
    },
    {
      key: 3,
      title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
      content:
        "By this and That from top university"
    },
    {
      key: 4,
      title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
      content:
        "By this and That from top university"
    },
    {
        key: 5,
        title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
        content:
          "By this and That from top university"
    },
    {
        key: 6,
        title: "Article Tiltle Long title about how children, teachers and parents needs KnowBoo",
        content:
          "By this and That from top university"
    }
  ];
  
  export default notes;
  