import { ToolButton } from "../../components/ToolButton";
import { StudentSettingsIcon } from "../../components/icons";
import { KEYS } from "../../keys";
import clsx from "clsx";
import "../../components/ToolIcon.scss";

type LockIconProps = {
  title?: string;
  isMobile?: boolean;
  onClick?: () => void;
};

export const SignUpButton = (props: LockIconProps) => {
  const showSignUp = () => {
    if (props.onClick == null) return;
    props.onClick();
  }
  
  return (
    <ToolButton
      className={clsx("Shape", { fillable: false })}
      type="button"
      icon={StudentSettingsIcon}
      name="editor-current-shape"
      title={`${props.title}`}
      aria-label={`${props.title}`}
      onClick={() => showSignUp()}
    />
  );
};