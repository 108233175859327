import { useState, useEffect, useCallback } from "react";
import HintPopUp from "../Hint/HintPopUp";

export const ReactionAppComponent = (props) => {
    console.log('props :>> ', props);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const onClose = useCallback(() => {
        props.setReaction("");
        props.setShowReaction(false);
      setPopupOpen(false);
    }, [setPopupOpen]);
  
    const openPopup = () => {
      setPopupOpen(true);
    };
  
    // Open the popup when the app component mounts
    useEffect(() => {
      if (!props.showReaction) return;
      openPopup();
    }, [props.showReaction]);
  
    return (
      <div>
        {isPopupOpen && props.reaction != undefined && (
          <HintPopUp
            reactionToShow={props.reaction}
            onClose={onClose}
            hintType={props.hintType}
          />
        )}
      </div>
    );
  }