import "./Parent.css";
import { IoArrowBackOutline } from "react-icons/io5";

function ParentViewPage(props) {
  const goBack = () => {
    if (props.back == undefined) return;
    props.back("parents");
  };

  return (
    <div>
      <button
        className="appButton"
        style={{ marginLeft: "1rem" }}
        onClick={() => goBack()}
      >
        <IoArrowBackOutline />
      </button>
      <fieldset
        className="classFieldSet parentView"
        style={{ marginTop: "1rem" }}
      >
        <legend className="legendNoHover">Parent information</legend>
        <p><b>Parent first name: </b>{props.firstName}</p>
        <p><b>Parent last name: </b>{props.lastName}</p>
        <p><b>Parent's UID: </b>{props.uid}</p>
        <p><b>Parent's child UID: </b>{props.studentUID}</p>
        <p><b>Parent's school: </b>{props.school}</p>
        <p><b>Parent's classroom: </b>{props.classroom}</p>
      </fieldset>
    </div>
  )
}

export default ParentViewPage;
