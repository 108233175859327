export const JSONPARSESTRIGFY = (data) => {
    return JSON.parse(JSON.stringify(data));
}

export const DISPLAYERROR = (e) => {
    console.error(e.message);
    console.error(e.stack);
    const [, lineno, colno] = e.stack.match(/(\d+):(\d+)/);
    console.error('Line:', lineno);
    console.error('Column:', colno);
}

export const KnowBoo_API_URL = 'https://knowbooserver-e09cd4f7ccfc.herokuapp.com';
// export const KnowBoo_API_URL = 'http://localhost:1234';