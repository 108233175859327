import './TeacherViewPage.css';
import { IoArrowBackOutline } from "react-icons/io5";

function TeacherViewPage(props) {
  const goBack = () => {
    if (props.back == undefined) return;
    props.back("teachers");
  };

  return (
    <div>
      <button
        className="appButton"
        style={{ marginLeft: "1rem" }}
        onClick={() => goBack()}
      >
        <IoArrowBackOutline />
      </button>
      <fieldset
        className="classFieldSet teacherView"
        style={{ marginTop: "1rem" }}
      >
        <legend className="legendNoHover">Teacher information</legend>
        <p><b>Teacher's first name: </b>{props.firstName}</p>
        <p><b>Teacher's last name: </b>{props.lastName}</p>
        <p><b>Teacher's email: </b>{props.email}</p>
        <p><b>Teacher's school: </b>{props.school}</p>
        <p><b>Teacher's classroom: </b>{props.classroom}</p>
        <p><b>Teacher's UID: </b>{props.uid}</p>
      </fieldset>
    </div>

  )
}

export default TeacherViewPage;
