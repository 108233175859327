import "./ExercisesList.css";
import { GiCheckMark } from "react-icons/gi";
import { useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  defaultLang,
  getLanguage,
  languages,
  setLanguage,
  t as tLayerUI,
} from "../../../i18n";
import CalcSad from "../../calcSad.mp4";
import CalcHappy from "../../calcHappy.mp4";
import { JSONPARSESTRIGFY } from "../../Utils";

type reactopnProps = {
  exercises: object[];
  updateCurrentExercise: (data: object) => void;
  onClose: () => void;
};

const ExercisesListDemo = ({
  exercises,
  updateCurrentExercise,
  onClose,
}: reactopnProps) => {
  const updateCurrentExerciseAndCloseWindow = (data: object) => {
    updateCurrentExercise(data);
    onClose();
  };

  return (
    <div className="coolBG">
      <div className="chatHeader">
        <p>Choose your exercise!</p>
        {/* <video
        autoPlay
        loop
        muted
        playsInline
        className="mascot"
        src={CalcHappy}
      /> */}
      </div>
      <div className="coolBGeffect">
          <div className="exercisesList">
            <h2 className="exerciseListTitle">Exercise list</h2>
            <div className="exercises">
              {exercises ? (
                exercises.map((data, index) => (
                  <button
                    className="appButton"
                    style={{ padding: ".3rem", margin: ".2rem" }}
                    key={index}
                    onClick={() => updateCurrentExerciseAndCloseWindow(data)}
                  >
                    {JSONPARSESTRIGFY(data).question}
                  </button>
                ))
              ) : (
                <p>error getting exercises</p>
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default ExercisesListDemo;
