import { useState, useEffect, useCallback } from "react";
import SignUpComponent from "../Components/SignUpComponent/SignUpComponent";

export const SignUpAppComponent = (props) => {
  const [isSignUpComponentOpen, setIsSignUpComponentOpen] = useState(false);

  const onBugComponentComponentClose = useCallback(() => {
    props.setSelectedComponent("");
    setIsSignUpComponentOpen(false);
  }, [isSignUpComponentOpen]);

  const onStudentComponentOpen = () => {
    setIsSignUpComponentOpen(true);
  };

  // Open the popup when the app component mounts
  useEffect(() => {
    onStudentComponentOpen();
  }, []);

  return (
    <div>
      {isSignUpComponentOpen && (
        <SignUpComponent close={() => onBugComponentComponentClose()} />
      )}
    </div>
  );
}