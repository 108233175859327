import { useState, useEffect } from 'react'
import { createClient } from "@supabase/supabase-js";
import Parent from '../Parent/ParentComponent';
import Student from '../Student/StudentButton';
import Teacher from '../Teacher/TeacherComponent';
import ParentViewPage from '../Parent/ParentViewPage';
import StudentViewPage from '../Student/StudentViewPage';
import TeacherViewPage from '../Teacher/TeacherViewPage';
import './ManagerPage.css';
import { JSONPARSESTRIGFY } from '../Utils';

function ManagerPage(props) {
  const [uid, setUID] = useState("");
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [school, setSchool] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageStudents, setErrorMessageStudents] = useState(null);
  const [errorMessageParents, setErrorMessageParents] = useState(null);
  const [errorMessageTeachers, setErrorMessageTeachers] = useState(null);
  const [selected, setSelected] = useState("students");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentUserFirstName, setCurrentUserFirstName] = useState("");
  const [currentUserLastName, setCurrentUserLastName] = useState("");
  const [currentUserUID, setCurrentUserUID] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [currentUserRoomLink, setCurrentUserRoomLink] = useState("");
  const [currentUserSchool, setCurrentUserSchool] = useState("");
  const [currentUserClassRoom, setCurrentUserClassRoom] = useState("");
  const [currentUserType, setCurrentUserType] = useState("");
  const [currentUserStudentUID, setCurrentUserStudentUID] = useState("");
  const [filter, setFilter] = useState("");
  const [supabase, setSupabase] = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session != null) {
        setUID(session.user.id);
        fetchManagerData(session.user.id);
      }
    });
  }, [supabase]);

  const fetchManagerData = async (userID) => {
    const { data: firstNameData, error: firstNameError } = await supabase.from("Users_FirstName").select().eq('uid', userID);
    if (firstNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(firstNameError).message);
      setErrorMessage(JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData != null) {
      if (firstNameData.length > 0) {
        setFirstName(firstNameData[0].firstName);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase.from("Users_LastName").select().eq('uid', userID);
    if (lastNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(lastNameError).message);
      setErrorMessage(JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData != null) {
      if (lastNameData.length > 0) {
        setLastName(lastNameData[0].lastName);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: schoolData, error: schoolError } = await supabase.from("Users_School").select().eq('uid', userID);
    if (schoolError) {
      console.error("Error: " + JSONPARSESTRIGFY(schoolError).message);
      setErrorMessage(JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData != null) {
      if (schoolData.length > 0) {
        console.log(schoolData[0].school);
        setSchool(schoolData[0].school);
        fetchAllStudents(schoolData[0].school);
        fetchAllParents(schoolData[0].school);
        fetchAllTeachers(schoolData[0].school);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  }

  const fetchAllStudents = async (schoolName) => {
    const { data, error } = await supabase.from("Users_Rule").select('*, Users_School(*)').eq("rule", "Student");
    if (error) {
      setErrorMessageStudents(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setStudents(JSONPARSESTRIGFY(data).filter(element => element.Users_School.school === schoolName));
        setErrorMessageStudents("");
      } else {
        setStudents(null);
        setErrorMessageStudents("Error: Can't get students data");
        console.error("Error: Can't get students data");
      }
    }
  }

  const fetchAllParents = async (schoolName) => {
    const { data, error } = await supabase.from("Users_Rule").select('*, Users_School(*)').eq("rule", "Parent");
    if (error) {
      setErrorMessageParents(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setParents(JSONPARSESTRIGFY(data).filter(element => element.Users_School.school === schoolName));
        setErrorMessageParents("");
      } else {
        setParents(null);
        setErrorMessageParents("Error: Can't get parents data");
        console.error("Error: Can't get parents data");
      }
    }
  }

  const fetchAllTeachers = async (schoolName) => {
    const { data, error } = await supabase.from("Users_Rule").select('*, Users_School(*)').eq("rule", "Teacher");
    if (error) {
      setErrorMessageTeachers(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setTeachers(JSONPARSESTRIGFY(data).filter(element => element.Users_School.school === schoolName));
        setErrorMessageTeachers("");
      } else {
        setTeachers(null);
        setErrorMessageTeachers("Error: Can't get teachers data");
        console.error("Error: Can't get teachers data");
      }
    }
  }

  const handleSelection = (num) => {
    const studentsLegend = document.getElementById("studentsLegend");
    const parentsLegend = document.getElementById("parentsLegend");
    const teachersLegend = document.getElementById("teachersLegend");

    studentsLegend.classList.remove("selected");
    parentsLegend.classList.remove("selected");
    teachersLegend.classList.remove("selected");

    switch (num) {
      case 0:
        studentsLegend.classList.add("selected");
        setSelected("students");
        break;
      case 1:
        parentsLegend.classList.add("selected");
        setSelected("parents");
        break;
      case 2:
        teachersLegend.classList.add("selected");
        setSelected("teachers");
        break;
      default: break;
    }
  }

  const handleCurrentUser = (data, type) => {
    setCurrentUserFirstName(data.firstName);
    setCurrentUserLastName(data.lastName);
    setCurrentUserUID(data.uid);
    setCurrentUserEmail(data.email);
    setCurrentUserSchool(data.school);
    setCurrentUserClassRoom(data.classroom);
    if (data.roomLink != null) {
      setCurrentUserRoomLink(data.roomLink);
    }
    if (data.studentUID != null) {
      setCurrentUserStudentUID(data.studentUID);
    }
    setCurrentUserType(type);
  }

  const clearCurrentUser = (type) => {
    setCurrentUserFirstName("");
    setCurrentUserLastName("");
    setCurrentUserUID("");
    setCurrentUserEmail("");
    setCurrentUserRoomLink("");
    setCurrentUserType("");
    setCurrentUserStudentUID("");
    setCurrentUserSchool("");
    setCurrentUserClassRoom("");
    setSelected(type);
  }

  return (
    <div>
      {currentUserType !== "" ?
        <>
          {
            currentUserType === "Teacher" ?
              <TeacherViewPage back={(type) => clearCurrentUser(type)} firstName={currentUserFirstName} lastName={currentUserLastName} uid={currentUserUID} school={currentUserSchool} classroom={currentUserClassRoom} />
              : currentUserType === "Parent" ?
                <ParentViewPage back={(type) => clearCurrentUser(type)} firstName={currentUserFirstName} lastName={currentUserLastName} uid={currentUserUID} studentUID={currentUserStudentUID} school={currentUserSchool} classroom={currentUserClassRoom} />
                : currentUserType === "Student" ?
                  <StudentViewPage back={(type) => clearCurrentUser(type)} firstName={currentUserFirstName} lastName={currentUserLastName} uid={currentUserUID} roomLink={currentUserRoomLink} email={currentUserEmail} school={currentUserSchool} classroom={currentUserClassRoom} />
                  : null
          }
        </>
        :
        <fieldset className='classFieldSet'>
          <div className='legends'>
            {
              selected === "students" ?
                <div id='studentsLegend' className='legend selected' onClick={() => handleSelection(0)}>Students:</div>
                :
                <div id='studentsLegend' className='legend' onClick={() => handleSelection(0)}>Students:</div>
            }
            {
              selected === "parents" ?
                <div id='parentsLegend' className='legend selected' onClick={() => handleSelection(1)}>Parents:</div>
                :
                <div id='parentsLegend' className='legend' onClick={() => handleSelection(1)}>Parents:</div>
            }
            {
              selected === "teachers" ?
                <div id='teachersLegend' className='legend selected' onClick={() => handleSelection(2)}>Teachers:</div>
                :
                <div id='teachersLegend' className='legend' onClick={() => handleSelection(2)}>Teachers:</div>
            }
          </div>
          <input className='searchBar' type="text" value={filter} placeholder='Search user here...' onChange={(e) => setFilter(e.target.value)} />
          {filter !== "" &&<p style={{margin: "1rem"}}>Searching for all users that contain: "{filter}"</p>}
          {
            selected == "students" ?
              students ?
                students
                  .map((data, index) => (
                    <Student
                      key={index}
                      filter={filter}
                      uid={JSONPARSESTRIGFY(data).uid}
                      onEnter={(userData, type) =>
                        handleCurrentUser(userData, type)
                      }
                    />
                  ))
                :
                <p className='error'>{errorMessageStudents}</p>
              : selected == "parents" ?
                parents ?
                  parents
                    .map((data, index) => (
                      <Parent
                        key={index}
                        filter={filter}
                        uid={JSONPARSESTRIGFY(data).uid}
                        onEnter={(data, type) => handleCurrentUser(data, type)}
                      />
                    ))
                  :
                  <p className='error'>{errorMessageParents}</p>
                :
                teachers ?
                  teachers
                    .map((data, index) => (
                      <Teacher
                        key={index}
                        filter={filter}
                        uid={JSONPARSESTRIGFY(data).uid}
                        onEnter={(data, type) => handleCurrentUser(data, type)}
                      />
                    ))
                  :
                  <p className='error'>{errorMessageTeachers}</p>
          }
        </fieldset>
      }
    </div>
  )
}

export default ManagerPage;
