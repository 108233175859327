import { RxHamburgerMenu } from "react-icons/rx";
import { GrContact } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { FcAbout } from "react-icons/fc";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { TbNews } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai";
import { MdFeaturedPlayList } from "react-icons/md";
import { JSONPARSESTRIGFY } from "../Utils";
import { useEffect, useState } from "react";
import { GrUser, GrUserSettings } from "react-icons/gr";
import { AiFillBug } from "react-icons/ai";
import { languages, t } from "../../i18n";
import { createClient } from "@supabase/supabase-js";
// import Confetti from "../Confetti.gif";
import Chart from "../Chart/Chart";
import Tal from "../tal.png";
import Vadim from "../vadim.png";
import Yali from "../yali.png";
import Idan from "../idan.png";
import articles from "./articles.js";
import Article from "./components/article";
import Logo from "../Logo_no_beckround.png";
import AccountPage from "../Account/AccountComponent";
import ParentPage from "../Parent/ParentPage";
import TeacherPage from "../Teacher/TeacherPage";
import AdminPage from "../Admin/AdminPage";
import BugReport from "./settings/bugReport";
import ManagerPage from "../Manager/ManagerPage";
// import KBOnATablet from "./KnowBoo_on_a_tablet.mp4";
import ExcalidrawApp from "../../excalidraw-app";
import StudentPage from "../Student/StudentPage";
import ErrorBoundary from "../ErrorBoundary";
import MathTablet from "./math_on_tablet.png";
import "./HomePage.css";
import Settings from "./settings/settings";

function HomePage() {
  const [uid, setUID] = useState("");
  const [rule, setRule] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userSchool, setUserSchool] = useState("");
  const [userClass, setUserClass] = useState("");
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  //-----------------------------------------------
  const [selected, setSelected] = useState("Home");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentLangCode, setCurrentLangCode] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
    document.addEventListener("click", (evt) => {
      const navMenu = document.getElementById("navMenu");
      const navMenuButton = document.getElementById("navMenuButton");
      if (!navMenu) return;
      let targetEl: any = evt.target; // clicked element      
      do {
        if(targetEl == navMenu || targetEl == navMenuButton) {
          return;
        }
        // Go up the DOM
        if(targetEl !== null) {
          targetEl = targetEl.parentNode;
        }
      } while (targetEl); 
      setShowMenu(false);
    });
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    afterFetchSupabaseFetchData();
  }, [supabase]);

  const afterFetchSupabaseFetchData = () => {
    supabase.auth.getSession().then((data: any) => {
      const session = data.data.session;
      if (session != null) {
        setUID(session.user.id);
        setRule(session.user.user_metadata.rule);
        console.log("UID: " + session.user.id);
        console.log("rule: " + session.user.user_metadata.rule);
        fetchData(session.user.user_metadata.rule, session.user.id);
        setSelected("Account");
        // supabase.auth.onAuthStateChange(async (event, session) => {
        //   // console.log(event);
        //   if (event === "PASSWORD_RECOVERY") {
        //     const newPassword = prompt("What would you like your new password to be?");
        //     const { data, error } = await supabase.auth
        //       .updateUser({ password: newPassword })

        //     if (data) alert("Password updated successfully!")
        //     if (error) alert("There was an error updating your password.")
        //   }
        // });
      }
    });
    getLang();
    document.addEventListener("click", closeDialogs);
  };

  const getLang = () => {
    const getCurrentLangCode = localStorage.getItem("currentLangCode");
    if (getCurrentLangCode == null) return;
    setCurrentLangCode(getCurrentLangCode);
  };

  const closeDialogs = (event: Event) => {
    closeBugReportDialogOnOutsideClick(event);
    closeSettingsDialogOnOutsideClick(event);
  };

  const closeBugReportDialogOnOutsideClick = (event: any) => {
    const dialog = document.getElementById("bugReportDialog");
    if (dialog == null) return;
    if (!event.target.contains(dialog)) return;
    closeBugReportDialog();
  };

  const closeSettingsDialogOnOutsideClick = (event: any) => {
    const dialog = document.getElementById("managerSettingsDialog");
    if (dialog == null) return;
    if (!event.target.contains(dialog)) return;
    closeSettingsDialog();
  };

  const closeBugReportDialog = () => {
    const dialog: any = document.getElementById("bugReportDialog");
    const bugReportForm = document.getElementById("bugReportForm");
    if (bugReportForm == null) {
      if (!dialog) return;
      dialog.close();
      return;
    }
    bugReportForm.classList.add("hide");
    setTimeout(() => {
      bugReportForm.classList.remove("hide");
      dialog.close();
    }, 400);
  };

  const closeSettingsDialog = () => {
    const dialog: any = document.getElementById("managerSettingsDialog");
    const settingsGRAB = document.getElementById("settingsGRAB");
    if (settingsGRAB == null) {
      dialog.close();
      return;
    }
    settingsGRAB.classList.add("hide");
    setTimeout(() => {
      settingsGRAB.classList.remove("hide");
      dialog.close();
    }, 400);
  };

  const openBugReportDialog = () => {
    const dialog: any = document.getElementById("bugReportDialog");
    dialog.showModal();
  };

  const openSettingsDialog = () => {
    const dialog: any = document.getElementById("managerSettingsDialog");
    dialog.showModal();
  };

  const fetchData = async (rule: string, userID: string) => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", userID);
    if (firstNameError) {
      console.error("error: " + JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData) {
      if (firstNameData.length > 0) {
        setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", userID);
    if (lastNameError) {
      console.error("error: " + JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData) {
      if (lastNameData.length > 0) {
        setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
      }
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", userID);
    if (emailError) {
      console.error("error: " + JSONPARSESTRIGFY(emailError).message);
    }
    if (emailData) {
      if (emailData.length > 0) {
        setEmail(JSONPARSESTRIGFY(emailData[0]).email);
      }
    }
    const { data: schoolData, error: schoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", userID);
    if (schoolError) {
      console.error("error: " + JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData) {
      if (schoolData.length > 0) {
        setUserSchool(JSONPARSESTRIGFY(schoolData[0]).school);
      }
    }
    const { data: classData, error: classError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", userID);
    if (classError) {
      console.error("error: " + JSONPARSESTRIGFY(classError).message);
    }
    if (classData) {
      if (classData.length > 0) {
        if (JSONPARSESTRIGFY(classData[0]).class !== null) {
          setUserClass(JSONPARSESTRIGFY(classData[0]).class);
        }
      }
    }
  };

  const updateCurrentLang = (code: string) => {
    localStorage.setItem("currentLangCode", code);
    setCurrentLangCode(code);
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    const dialog: any = document.getElementById("managerSettingsDialog");
    const bugReportDialog: any = document.getElementById("bugReportDialog");
    if (error) {
      console.error("error: " + JSONPARSESTRIGFY(error).message);
    } else {
      setUID("");
      setRule("");
      setSelected("Home");
      bugReportDialog.close();
      dialog.close();
    }
    setShowMenu(false);
  };

  const setSelectedAndCloseMenu = (selectedParam: string) => {
    setSelected(selectedParam);
    setShowMenu(false);
  }

  return (
    <>
      {selected === "Excalidraw" ? (
        <ExcalidrawApp />
      ) : (
        <div className="h-screen full-screen">
          <dialog id="bugReportDialog">
            <BugReport close={() => closeBugReportDialog()} />
          </dialog>
          <dialog id="managerSettingsDialog">
              <Settings
                close={() => closeSettingsDialog()}
                setErrorMessage={(msg: string) => setErrorMessage(msg)}
                errorMessage={errorMessage}
              />
          </dialog>
          <nav className="bg-blue-900 py-2 h-30 w-full grid grid-cols-9 items-center">
            <img
              className="logoHomePage h-28 w-auto justify-self-center"
              src={Logo}
              alt="logo"
            />
            <div className="desktopNavMenu px-20 mx-10 col-span-7 grid grid-cols-7 items-center :lg px-20 mx-10 col-span-6 grid grid-cols-7 items-center">
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("Home")}
              >
                Home
              </a>
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("Features")}
              >
                Features
              </a>
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("News")}
              >
                News
              </a>
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("Updates")}
              >
                Updates
              </a>
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("About")}
              >
                About
              </a>
              <a
                className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                onClick={() => setSelectedAndCloseMenu("Contact")}
              >
                Contact
              </a>
              {uid === "" ? (
                <a
                  className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                  onClick={() => setSelectedAndCloseMenu("Sign")}
                >
                  Log in
                </a>
              ) : (
                <a
                  className="text-blue-200 hover:text-blue-300 font-mono font-bold text-center text-3xl"
                  onClick={() => setSelectedAndCloseMenu("Account")}
                >
                  Account
                </a>
              )}
            </div>
            {showMenu ? (
              <div id="navMenu">
                <div className="navMenu">
                  <a
                    className="flexHomePage"
                    onClick={() => openBugReportDialog()}
                  >
                    <p>Bug report</p>
                    <AiFillBug className="AiFillBug" />
                  </a>
                  {rule !== "" ? (
                    <a
                      className="flexHomePage"
                      onClick={() => openSettingsDialog()}
                    >
                      <p>Account settings</p>
                      <GrUserSettings className="GrUserSettings" />
                    </a>
                  ) : null}
                  {rule !== "" ? (
                    <a className="flexHomePage" onClick={() => signOut()}>
                      <p>Log out</p>
                      <BiLogOutCircle className="GrUserSettings" />
                    </a>
                  ) : null}
                  <select
                    className="homepageLanguageListMobile"
                    onChange={(e) => updateCurrentLang(e.target.value)}
                    value={currentLangCode}
                  >
                    {languages
                      .filter(
                        (l) =>
                          l.code === "en" ||
                          l.code === "he-IL" ||
                          l.code === "fa-IR",
                      )
                      .map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mobileNavMenu">
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("Home")}
                  >
                    <p>Home</p>
                    <AiOutlineHome className="GrUserSettings" />
                  </a>
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("Features")}
                  >
                    <p>Features</p>
                    <MdFeaturedPlayList className="GrUserSettings" />
                  </a>
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("News")}
                  >
                    <p>News</p>
                    <TbNews className="GrUserSettings" />
                  </a>
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("Updates")}
                  >
                    <p>Updates</p>
                    <MdOutlineTipsAndUpdates className="GrUserSettings" />
                  </a>
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("About")}
                  >
                    <p>About</p>
                    <FcAbout className="GrUserSettings" />
                  </a>
                  <a
                    className="flexHomePage"
                    onClick={() => setSelectedAndCloseMenu("Contact")}
                  >
                    <p>Contact</p>
                    <GrContact className="GrUserSettings" />
                  </a>
                  {uid === "" ? (
                    <a
                      className="flexHomePage"
                      onClick={() => setSelectedAndCloseMenu("Sign")}
                    >
                      <p>Log in</p>
                      <MdAccountCircle className="GrUserSettings" />
                    </a>
                  ) : (
                    <a
                      className="flexHomePage"
                      onClick={() => setSelectedAndCloseMenu("Account")}
                    >
                      <p>Account</p>
                      <MdAccountCircle className="GrUserSettings" />
                    </a>
                  )}
                  <a
                    className="flexHomePage"
                    onClick={() => openBugReportDialog()}
                  >
                    <p>Bug report</p>
                    <AiFillBug className="AiFillBug" />
                  </a>
                  {rule !== "" ? (
                    <a
                      className="flexHomePage"
                      onClick={() => openSettingsDialog()}
                    >
                      <p>Account settings</p>
                      <GrUserSettings className="GrUserSettings" />
                    </a>
                  ) : null}
                  {rule !== "" ? (
                    <a className="flexHomePage" onClick={() => signOut()}>
                      <p>Log out</p>
                      <BiLogOutCircle className="GrUserSettings" />
                    </a>
                  ) : null}
                  <select
                    className="homepageLanguageListMobile"
                    onChange={(e) => updateCurrentLang(e.target.value)}
                    value={currentLangCode}
                  >
                    {languages
                      .filter(
                        (l) =>
                          l.code === "en" ||
                          l.code === "he-IL" ||
                          l.code === "fa-IR",
                      )
                      .map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ) : null}
            <RxHamburgerMenu
              className="navMenuButton"
              id="navMenuButton"
              onClick={() => setShowMenu(!showMenu)}
            />
          </nav>
          {selected === "Home" ? (
            <div>
              <div className="videoTextContainer bg-white text-black flex text-xl items-center">
                {/*<img className='h-96 w-auto pr-20' src={MathTablet} alt="tablet picture" width="500" height="500" /> */}
                {/* <video
                  className="h-96 w-auto lg: h-134 w-auto"
                  src={KBOnATablet}
                  autoPlay
                  loop
                  muted
                /> */}
                <div className="videoTextContainerText text-center pr-10 justify-center">
                  <p className="text-4xl font-bold">
                    Your child's private tutor
                  </p>
                  <p className="text-4xl font-bold">wherever you want</p>
                  <p className="text-4xl font-bold">whenever you want!</p>
                  {uid === "" ? (
                    <button
                      disabled
                      onClick={() => setSelected("Excalidraw")}
                      className="bg-slate-900 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 mx-auto mt-14 rounded-lg w-full flex items-center justify-center sm:w-auto dark:bg-sky-500 dark:highlight-white/20 dark:hover:bg-sky-400"
                    >
                      Try it now
                      (Coming soon!)
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="articlesList px-5 py-10 grid gap-16 grid-cols-3 justify-center min-h-min">
                {articles.map((note) => (
                  <Article
                    key={note.key}
                    title={note.title}
                    content={note.content}
                  />
                ))}
              </div>
            </div>
          ) : selected == "News" ? (
            <div className="news">
              <h2 className="subtitle">News</h2> 
            </div>
          ) : selected == "News" ? (
            <div className="news">
              <h2 className="subtitle">News</h2>
              <div className="update">
                <h3>Circle Hackathon 2023 we are coming!</h3>
                <p>Expect to see us there.</p>
                <img
                  className="img"
                  width={500}
                  src="https://circles-s3-bucket.s3.eu-west-1.amazonaws.com/account/645e2388da8237605dcb4e05banner/images/%20forcircles.png"
                />
              </div>
            </div>
          ) : selected == "Features" ? (
            <div className="features">
              <h2 className="subtitle">Features!</h2>
              <div className="update">
                <h3>Major updates to our UI, UX and animations!</h3>
                <p>
                  We have updated our UI to look better and be more informative.
                </p>
                <p>We have updated our UX by adding confetti! 🥳✨👑🏆</p>
                <p>We have updated our animations to feel much more alive.</p>
                {/* <img className="img" width={750} src={Confetti} /> */}
              </div>
            </div>
          ) : selected == "About" ? (
            <div className="about">
              <h2 className="subtitle">About</h2>
              <div className="picturesContainer">
                <div className="pictureContainer">
                  <h2>Vadim</h2>
                  <h3>Marketing and Bussiness</h3>
                  <img width={200} src={Vadim} />
                </div>
                <div className="pictureContainer">
                  <h2>Yali</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Yali} />
                </div>
                <div className="pictureContainer">
                  <h2>Tal</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Tal} />
                </div>
                <div className="pictureContainer">
                  <h2>Idan</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Idan} />
                </div>
              </div>
            </div>
          ) : selected == "Updates" ? (
            <div className="updates">
              <h2 className="subtitle">Updates!</h2>
              <div className="update">
                <h3>Major updates to our UI, UX and animations!</h3>
                <p>
                  We have updated our UI to look better and be more informative.
                </p>
                <p>We have updated our UX by adding confetti! 🥳✨👑🏆</p>
                <p>We have updated our animations to feel much more alive.</p>
                {/* <img className="img" width={750} src={Confetti} /> */}
              </div>
              <div className="update">
                <h3>⏳ Major updates to our AFK system! ⏳</h3>
                <p>
                  We have updated our AFK system to provide hints after 60
                  seconds on non activity.
                </p>
                <p>Now you can choose an exercise.</p>
              </div>
              <div className="update">
                <h3>Major updates to our exercises!</h3>
                <p>Now you can choose an exercise.</p>
                <p>And after finishing an exercise it will go to next one!</p>
              </div>
              <div className="update">
                <h3>🤖 Hello ChatGPT! 🤖</h3>
                <p>We have added ChatGPT to KnowBoo!</p>
              </div>
              <div className="update">
                <h3>🧑‍🏫 Classes are now available! 👩‍🏫</h3>
                <p>Teachers we heard you! we now have classes in KnowBoo!</p>
              </div>
            </div>
          ) : selected == "About" ? (
            <div className="about">
              <h2 className="subtitle">About</h2>
              <div className="picturesContainer">
                <div className="pictureContainer">
                  <h2>Vadim</h2>
                  <h3>Marketing and Bussiness</h3>
                  <img width={200} src={Vadim} />
                </div>
                <div className="pictureContainer">
                  <h2>Yali</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Yali} />
                </div>
                <div className="pictureContainer">
                  <h2>Tal</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Tal} />
                </div>
                <div className="pictureContainer">
                  <h2>Idan</h2>
                  <h3>Developer</h3>
                  <img width={200} src={Idan} />
                </div>
              </div>
            </div>
          ) : selected == "Contact" ? (
            <div className="contact">
              <h2 className="subtitle">Contact</h2>
              <form
                className="contactForm"
                action="https://formsubmit.co/contact@knowboo.com"
                method="POST"
              >
                <h3>Stay in touch with us!</h3>
                <label htmlFor="name">🙋Name🙋</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name here..."
                  required
                />
                <label htmlFor="email">✉️Email✉️</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email here..."
                  required
                />
                <label htmlFor="tel">📱Phone📱</label>
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  placeholder="Enter your phone here..."
                  required
                />
                <label htmlFor="msg">Message</label>
                <textarea
                  rows={4}
                  cols={50}
                  name="msg"
                  id="msg"
                  placeholder="Enter your message here..."
                  required
                />
                <button className="appButton" type="submit">
                  Submit
                </button>
              </form>
            </div>
          ) : selected == "Sign" ? (
            <div>
              <h2 className="subtitle">Sign in/up</h2>
              <AccountPage
                fetchData={(rule: string, userID: string) =>
                  fetchData(rule, userID)
                }
                setRule={(newRule: string) => setRule(newRule)}
                setUID={(newUID: string) => setUID(newUID)}
                setSelected={(newSelected: string) => setSelected(newSelected)}
              />
            </div>
          ) : selected == "Account" ? (
            <div>
              {rule === "Parent" ? (
                <div>
                  <p className="welcomeTitle">Welcome Parent</p>
                  <ParentPage openSettingsDialog={() => openSettingsDialog()}/>
                </div>
              ) : rule === "Teacher" ? (
                <div>
                  <p className="welcomeTitle">
                    {t("KnowBoo.TeacherPage.Wellcome")}
                  </p>
                  <TeacherPage signOut={signOut} />
                </div>
              ) : rule === "Admin" ? (
                <div>
                  <p className="welcomeTitle">Welcome Admin</p>
                  <AdminPage />
                </div>
              ) : rule === "Manager" ? (
                <div>
                  <p className="welcomeTitle">Welcome Manager</p>
                  <ManagerPage />
                </div>
              ) : rule === "Student" ? (
                <div>
                  <p className="welcomeTitle">Welcome Student</p>
                  <StudentPage onEnter={() => setSelected("Excalidraw")} />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default HomePage;

// To Do
//- להקטין את הסמל של knowboo
// - להחליף את הסיין אין ללוג אין
// - להצר את הנב בר
// - לעשות הבר שהקיטוב יגדל קצת ״ירחף למעלה״ קצת
// - לחפש טאבלט בלי צל
// - לבדוק אם אפשר לקחת את התמונה של הטאבלט
// - אפשר לשנות את הנב בר לרקע המשתנה של עידן
// - להוסיף במקום נסה היום לנסה בחופשי
// - מצד ימין לו אבל שיהיה רק נסה בחינם וזהו
// - להוסיף לעמוד הראשי עוד בלוקים של הפיצ׳רים
// - למטה לעשות את הכתבות בבלוקים יותר קטנים ובצבע כחול כהה
// - לעשות את הדמו בלי הרשמה ורק אחרי שהם משחקים עם הדמו כמה דקות להקפיץ את ההרשמה
// - לשנות את השפה שתהיה דגלים ולחלק וחלק מה בר
// - הארטיקלז בלוקוס חצי ברוחב ופי 2 בגובהה
// - במעבר לדמו לעשות מעבר קצר עם קישקוש ורעש של קישקוש עיפרון
// - בדף הבית לעשות במקום דפים חדשים שהכפתורים בנב בר מוריד לאזור הרצויי

// -אצל המורה להשקיע בניתוח ידע
