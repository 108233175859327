import { useState, useEffect } from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Chart from '../Chart/Chart';
import { t } from "../../i18n";
import { createClient } from "@supabase/supabase-js";
import Student from '../Student/StudentComponent';
import StudentViewPage from '../Student/StudentViewPage';
import HomeworkComponent from './ClassComponents/HomeworkComponent';
import { JSONPARSESTRIGFY } from '../Utils';
import "./Class.css";

function ClassPage(props) {
  const [students, setStudents] = useStateWithCallbackLazy([]);
  const [homework, setHomework] = useStateWithCallbackLazy([]);
  const [exams, setExams] = useStateWithCallbackLazy([]);
  const [assigments, setAssigments] = useStateWithCallbackLazy([]);
  const [exercises, setExercises] = useStateWithCallbackLazy([]);
  //--------------------------------------------------
  const [studentCount, setStudentCount] = useState(0);
  const [homeworkCount, setHomeworkCount] = useState(0);
  const [examCount, setExamCount] = useState(0);
  const [exerciseCount, setExerciseCount] = useState(0);
  const [assigmentCount, setAssigmentCount] = useState(0);
  //---------------------------------------------
  const [currentStudentFirstName, setCurrentStudentFirstName] = useState("");
  const [currentStudentLastName, setCurrentStudentLastName] = useState("");
  const [currentStudentUID, setCurrentStudentUID] = useState("");
  const [currentStudentEmail, setCurrentStudentEmail] = useState("");
  const [currentStudentClassroom, setCurrentStudentClassroom] = useState("");
  const [currentStudentSchool, setCurrentStudentSchool] = useState("");
  const [currentStudentRoomLink, setCurrentStudentRoomLink] = useState("");
  //---------------------------------------------------
  // const [uid, setUID] = useState("");
  const [selected, setSelected] = useState("classStudentsLegend");
  const [errorMessage, setErrorMessage] = useState(null);
  const [supabase, setSupabase] = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  }

  useEffect(() => {
    if (supabase === undefined) return;
    fetchStudents();
  }, [supabase]);


  const fetchStudents = async () => {
    let studentsRule;
    const { data: ruleData, error: ruleError} = await supabase.from("Users_Class").select("*, Users_Rule(*)").eq("class", props.classroom).order('uid', { ascending: false });
    if (ruleError) {
      console.error("Error: " + JSONPARSESTRIGFY(ruleError).message);
      setErrorMessage(ruleError);
    }
    if (ruleData != null) {
      if (ruleData.length > 0) {
        studentsRule = JSONPARSESTRIGFY(ruleData).filter(element => element.Users_Rule.rule === "Student");
        setStudents(studentsRule);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get students rule data");
        setErrorMessage("Error: Can't get students rule data");
      }
    }
    const { data: schoolData, error: schoolError } = await supabase.from("Users_Rule").select('*, Users_School(*)').eq("rule", "Student").order('uid', { ascending: false });
    if (schoolError) {
      setErrorMessage(JSONPARSESTRIGFY(schoolError).message);
      console.error("Error: " + JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData != null) {
      if (schoolData.length > 0) {
        let newStudentList = [];
        const studentsSchool = JSONPARSESTRIGFY(schoolData).filter(element => element.Users_School.school === props.school);
        for (let index = 0; index < studentsRule.length; index++) {
          const studentsRuleElement = studentsRule[index];
          for (let j = 0; j < studentsSchool.length; j++) {
            const studentsSchoolElement = studentsSchool[j];
            if (studentsRuleElement.uid === studentsSchoolElement.uid) {
              newStudentList.push(studentsRuleElement);
            }
          }
        }
        setStudents(newStudentList);
        setErrorMessage("");
      } else {
        setStudents(null);
        setErrorMessage("Error: Can't get student school data");
        console.error("Error: Can't get student school data");
      }
    }
  }

  // const fetchHomework = async () => {
  //   setHomework([]);
  //   setErrorMessage("Loading...");
  //   const { data, error } = await supabase.from("Classes").select().eq('name', props.classroom);
  //   if (error) {
  //     console.error("Error: " + JSONPARSESTRIGFY(error).message);
  //     setErrorMessage(error);
  //   }
  //   if (data != null) {
  //     if (data[0].homework != null && data[0].homework.length > 0) {
  //       setHomework(data[0].homework);
  //       setErrorMessage(null);
  //     } else {
  //       console.error("Error: Can't get students data");
  //       setErrorMessage("Error: Can't get students data");
  //     }
  //   }
  // }

  const handleHomeworkCount = (newCount) => {
    if (newCount < 0) return;
    setHomeworkCount(newCount);
  }

  const handleExamCount = (newCount) => {
    if (newCount < 0) return;
    setExamCount(newCount);
  }


  const handleExerciseCount = (newCount) => {
    if (newCount < 0) return;
    setExerciseCount(newCount);
  }

  const handleAssigmentCount = (newCount) => {
    if (newCount < 0) return;
    setAssigmentCount(newCount);
  }

  const handleSelection = (num) => {
    const classStudentsLegend = document.getElementById("classStudentsLegend");
    const classStatsLegend = document.getElementById("classStatsLegend");
    const classExamsLegend = document.getElementById("classExamsLegend");
    const classHomeworkLegend = document.getElementById("classHomeworkLegend");
    const classExercisesLegend = document.getElementById("classExercisesLegend");
    const classAssignmentsLegend = document.getElementById("classAssignmentsLegend");

    classStudentsLegend.classList.remove("selected");
    classStatsLegend.classList.remove("selected");
    classExamsLegend.classList.remove("selected");
    classHomeworkLegend.classList.remove("selected");
    classExercisesLegend.classList.remove("selected");
    classAssignmentsLegend.classList.remove("selected");

    switch (num) {
      case 0:
        classStudentsLegend.classList.add("selected");
        setSelected("classStudentsLegend");
        break;
      case 1:
        classStatsLegend.classList.add("selected");
        setSelected("classStatsLegend");
        break;
      case 2:
        classExamsLegend.classList.add("selected");
        setSelected("classExamsLegend");
        break;
      case 3:
        classHomeworkLegend.classList.add("selected")
        setSelected("classHomeworkLegend");
        break;
      case 4:
        classExercisesLegend.classList.add("selected")
        setSelected("classExercisesLegend");
        break;
      case 5:
        classAssignmentsLegend.classList.add("selected")
        setSelected("classAssignmentsLegend");
        break;
      default: break;
    }
  };

  const handleCurrentStudent = (data) => {
    console.log(data);
    setCurrentStudentFirstName(data.firstName);
    setCurrentStudentLastName(data.lastName);
    setCurrentStudentUID(data.uid);
    setCurrentStudentEmail(data.email);
    setCurrentStudentRoomLink(data.roomLink);
    setCurrentStudentSchool(data.school);
    setCurrentStudentClassroom(data.classroom);
    setStudentCount(0);
    setStudents([], () => {
      fetchStudents();
    });
  }

  const clearCurrentStudent = () => {
    setCurrentStudentFirstName("");
    setCurrentStudentLastName("");
    setCurrentStudentUID("");
    setCurrentStudentEmail("");
    setCurrentStudentRoomLink("");
    setCurrentStudentSchool("");
    setCurrentStudentClassroom("");
  }

  const goBack = () => {
    if (props.back == undefined) return;
    props.back();
  }

  const updateStudents = () => {
    setStudents([], () => {
      fetchStudents();
    });
  }

  const updateHomework = () => {
    // setStudents([], () => {
    //   fetchStudents();
    // });
  }

  // const updateExams = () => {
  //   // setStudents([], () => {
  //   //   fetchStudents();
  //   // });
  // }

  // const updateExercises = () => {
  //   // setStudents([], () => {
  //   //   fetchStudents();
  //   // });
  // }

  // const updateAssigments = () => {
  //   // setStudents([], () => {
  //   //   fetchStudents();
  //   // });
  // }

  return (
    <div>
      {currentStudentLastName != "" && currentStudentFirstName != "" && currentStudentEmail != "" && currentStudentUID != "" ?
        <StudentViewPage firstName={currentStudentFirstName} lastName={currentStudentLastName} email={currentStudentEmail} roomLink={currentStudentRoomLink} classroom={currentStudentClassroom} school={currentStudentSchool} uid={currentStudentUID} back={() => clearCurrentStudent()} />
        :
        <div>
          <button className='appButton' style={{ marginLeft: "1rem" }} onClick={() => goBack()}><IoArrowBackOutline /></button>
          <h1 className='title'>{t("KnowBoo.TeacherPage.Class")}: {props.classroom}</h1>
          <fieldset className='classFieldSet'>
            <div className='legends' style={{ width: "50rem" }}>
              <div id="classStudentsLegend" className='legend selected' onClick={() => handleSelection(0)}>{t("KnowBoo.TeacherPage.ClassStudents")}</div>
              <div id="classStatsLegend" className='legend' onClick={() => handleSelection(1)}>{t("KnowBoo.TeacherPage.ClassStats")}</div>
              <div id="classExamsLegend" className='legend' onClick={() => handleSelection(2)}>{t("KnowBoo.TeacherPage.ClassExams")}</div>
              <div id="classHomeworkLegend" className='legend' onClick={() => handleSelection(3)}>{t("KnowBoo.TeacherPage.ClassHomework")}</div>
              <div id="classExercisesLegend" className='legend' onClick={() => handleSelection(4)}>{t("KnowBoo.TeacherPage.ClassExercises")}</div>
              <div id="classAssignmentsLegend" className='legend' onClick={() => handleSelection(5)}>{t("KnowBoo.TeacherPage.ClassAssignments")}</div>
            </div>
            <div className="fieldSetContent">
              {
                selected == "classStudentsLegend" ?
                  <>
                    <p>{t("KnowBoo.TeacherPage.ClassStudents")}</p>
                    {errorMessage && <p className='error'>{errorMessage}</p>}
                    <div className='classesContainer'>
                      {
                        students.length > 0 &&
                        students.map((data, index) => (
                          <Student key={index} className={props.classroom} modeProp="student" uid={data.uid} onEnter={(data) => handleCurrentStudent(data)} updateStudents={() => updateStudents()} />
                        ))
                      }
                    </div>
                  </>
                  : selected === "classStatsLegend" ?
                    <>
                      <p>Class stats</p>
                    </>
                    : selected === "classExamsLegend" ?
                      <>
                        <p>{t("KnowBoo.TeacherPage.ClassExams")}</p>
                        {errorMessage && <p className='error'>{errorMessage}</p>}
                        <div className='classesContainer'>
                          {
                            exams.length > 0 &&
                            exams.map((data, index) => (
                              <p key={index}>hello</p>
                            ))
                          }
                          {
                            examCount > 0 &&
                            Array.apply(null, { length: examCount }).map((_, index) => (
                              <p key={index}>hello</p>
                            ))
                          }
                        </div>
                        <button className="addButton" onClick={() => handleExamCount(examCount + 1)}>+</button>
                        <button className="deductButton" onClick={() => handleExamCount(examCount - 1)}>-</button>
                      </>
                      : selected === "classHomeworkLegend" ?
                        <>
                          <p>{t("KnowBoo.TeacherPage.ClassHomework")}</p>
                          {errorMessage && <p className='error'>{errorMessage}</p>}
                          <div className='classesContainer'>
                            {
                              homework.length > 0 &&
                              homework.map((data, index) => (
                                <HomeworkComponent updateHomework={() => updateHomework()} questionParam={data} className={props.classroom} key={index} modeProp='view' />
                              ))
                            }
                            {
                              homeworkCount > 0 &&
                              Array.apply(null, { length: homeworkCount }).map((_, index) => (
                                <HomeworkComponent updateHomework={() => updateHomework()} questionParam="" className={props.classroom} key={index} modeProp='input' />
                              ))
                            }
                          </div>
                          <button className="addButton" onClick={() => handleHomeworkCount(homeworkCount + 1)}>+</button>
                          <button className="deductButton" onClick={() => handleHomeworkCount(homeworkCount - 1)}>-</button>
                        </>
                        : selected === "classExercisesLegend" ?
                          <>
                            <p>{t("KnowBoo.TeacherPage.ClassExercises")}</p>
                            {errorMessage && <p className='error'>{errorMessage}</p>}
                            <div className='classesContainer'>
                              {
                                exercises.length > 0 &&
                                exercises.map((data, index) => (
                                  <p key={index}>hello</p>
                                ))
                              }
                              {
                                exerciseCount > 0 &&
                                Array.apply(null, { length: exerciseCount }).map((_, index) => (
                                  <p key={index}>hello</p>
                                ))
                              }
                            </div>
                            <button className="addButton" onClick={() => handleExerciseCount(exerciseCount + 1)}>+</button>
                            <button className="deductButton" onClick={() => handleExerciseCount(exerciseCount - 1)}>-</button>
                          </>
                          : selected === "classAssignmentsLegend" ?
                            <>
                              <p>{t("KnowBoo.TeacherPage.ClassAssignments")}</p>
                              {errorMessage && <p className='error'>{errorMessage}</p>}
                              <div className='classesContainer'>
                                {
                                  assigments.length > 0 &&
                                  assigments.map((data, index) => (
                                    <p key={index}>hello</p>
                                  ))
                                }
                                {
                                  assigmentCount > 0 &&
                                  Array.apply(null, { length: assigmentCount }).map((_, index) => (
                                    <p key={index}>hello</p>
                                  ))
                                }
                              </div>
                              <button className="addButton" onClick={() => handleAssigmentCount(assigmentCount + 1)}>+</button>
                              <button className="deductButton" onClick={() => handleAssigmentCount(assigmentCount - 1)}>-</button>
                            </>
                            : null
              }
            </div>

          </fieldset>
        </div>
      }
    </div>
  )
}

export default ClassPage;
