import { useSetAtom } from "jotai";
import { appLangCodeAtom } from "..";
import { getLanguage, languages, useI18n } from "../../i18n";
import React, { useEffect } from "react";

export const LanguageList = ({ style }: { style?: React.CSSProperties }) => {
  const { t, langCode } = useI18n();
  const setLangCode = useSetAtom(appLangCodeAtom);

  useEffect(() => {
    const langLocalStorage = localStorage.getItem("currentLangCode");
    if (langLocalStorage == null) return;
    setLangCode(langLocalStorage);
  }, []);

  const updateJotaiAndLocalStorage = (target: any) => {
    setLangCode(target.value);
    localStorage.setItem("currentLangCode", target.value);
  };

  // direction: rtl !important;

  return (
    <>
      {getLanguage().rtl ? (
        <select
          className="dropdown-select dropdown-select__language dropdown-select__language_rtl"
          onChange={({ target }) => {
            updateJotaiAndLocalStorage(target);
          }}
          value={langCode}
          aria-label={t("buttons.selectLanguage")}
          style={style}
        >
          {languages
            .filter(
              (l) =>
                l.code === "en" || l.code === "he-IL" || l.code === "fa-IR",
            )
            .map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
        </select>
      ) : (
        <select
          className="dropdown-select dropdown-select__language"
          onChange={({ target }) => {
            updateJotaiAndLocalStorage(target);
          }}
          value={langCode}
          aria-label={t("buttons.selectLanguage")}
          style={style}
        >
          {languages
            .filter(
              (l) =>
                l.code === "en" || l.code === "he-IL" || l.code === "fa-IR",
            )
            .map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
        </select>
      )}
    </>
  );
};
