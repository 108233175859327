import { useState, useEffect } from "react";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import Exercise from "../Exercise/Exercise";
import { IoArrowBackOutline } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import "./StudentViewPage.css";
import { t } from "../../i18n";
import { JSONPARSESTRIGFY } from "../Utils";

type props = {
  uid: string;
  firstName: string;
  lastName: string;
  roomLink: string;
  email: string;
  school: string;
  classroom: string;
  back?: (selected: string) => void;
  openSettingsDialog?: () => void;
};

function StudentViewPage({
  uid,
  firstName,
  lastName,
  roomLink,
  email,
  back,
  school,
  classroom,
  openSettingsDialog,
}: props) {
  const [selected, setSelected] = useState("homeworkLegend");
  const [exercises, setExercises] = useState([]);
  const [exams, setExams] = useState([]);
  const [assigments, setAssigments] = useState([]);
  const [homework, setHomework] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [supabase, setSupabase]: any = useState();
  const [pleaseSelectChildUID, setPleaseSelectChildUID] = useState(false);

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    if (uid == "none") {
      setPleaseSelectChildUID(true);
    } else if (uid !== "") {
      fetchExercises(uid);
      fetchAsssigments(uid);
      fetchExams(uid);
      fetchHomework(uid);
    } else {
      supabase.auth.getSession().then((data: any) => {
        const session = data.data.session;
        if (session != null) {
          fetchExercises(session.user.id);
          fetchAsssigments(session.user.id);
          fetchExams(session.user.id);
          fetchHomework(session.user.id);
        }
      });
    }
  }, [supabase]);

  const fetchExercises = async (studentUID: string) => {
    // get student's answers DATA
    const { data: answersData, error: answersError } = await supabase
      .from("Users_Exercises")
      .select()
      .eq("uid", studentUID);
    if (answersError) {
      console.log("Error: " + JSONPARSESTRIGFY(answersError).message);
      setErrorMessage(JSONPARSESTRIGFY(answersError).message);
    }
    if (answersData != null) {
      if (answersData.length > 0) {
        setExercises(JSONPARSESTRIGFY(answersData[0]).exercises[0].questions);
        setErrorMessage("");
      } else {
        setExercises([]);
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  };

  const fetchExams = async (studentUID: string) => {
    // get student's answers DATA
    const { data: answersData, error: answersError } = await supabase
      .from("Users_Exams")
      .select()
      .eq("uid", studentUID);
    if (answersError) {
      console.log("Error: " + JSONPARSESTRIGFY(answersError).message);
      setErrorMessage(JSONPARSESTRIGFY(answersError).message);
    }
    if (answersData != null) {
      if (answersData.length > 0) {
        setExams(JSONPARSESTRIGFY(answersData[0]).exams[0].questions);
        setErrorMessage("");
      } else {
        setExams([]);
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  };

  const fetchHomework = async (studentUID: string) => {
    // get student's answers DATA
    const { data: answersData, error: answersError } = await supabase
      .from("Users_Homework")
      .select()
      .eq("uid", studentUID);
    if (answersError) {
      console.log("Error: " + JSONPARSESTRIGFY(answersError).message);
      setErrorMessage(JSONPARSESTRIGFY(answersError).message);
    }
    if (answersData != null) {
      if (answersData.length > 0) {
        setHomework(JSONPARSESTRIGFY(answersData[0]).homework[0].questions);
        setErrorMessage("");
      } else {
        setHomework([]);
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  };

  const fetchAsssigments = async (studentUID: string) => {
    // get student's answers DATA
    const { data: answersData, error: answersError } = await supabase
      .from("Users_Assigments")
      .select()
      .eq("uid", studentUID);
    if (answersError) {
      console.log("Error: " + JSONPARSESTRIGFY(answersError).message);
      setErrorMessage(JSONPARSESTRIGFY(answersError).message);
    }
    if (answersData != null) {
      if (answersData.length > 0) {
        setAssigments(JSONPARSESTRIGFY(answersData[0]).assigments[0].questions);
        setErrorMessage("");
      } else {
        setAssigments([]);
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  };

  const handleSelection = (num: number) => {
    const homeworkLegend = document.getElementById("homeworkLegend");
    const examsLegend = document.getElementById("examsLegend");
    const exercisesLegend = document.getElementById("exercisesLegend");
    const assigmentsLegend = document.getElementById("assigmentsLegend");

    if (homeworkLegend != null) homeworkLegend.classList.remove("selected");
    if (examsLegend != null) examsLegend.classList.remove("selected");
    if (exercisesLegend != null) exercisesLegend.classList.remove("selected");
    if (assigmentsLegend != null) assigmentsLegend.classList.remove("selected");

    switch (num) {
      case 0:
        if (homeworkLegend != null) homeworkLegend.classList.add("selected");
        setSelected("homeworkLegend");
        break;
      case 1:
        if (examsLegend != null) examsLegend.classList.add("selected");
        setSelected("examsLegend");
        break;
      case 2:
        if (exercisesLegend != null) exercisesLegend.classList.add("selected");
        setSelected("exercisesLegend");
        break;
      case 3:
        if (assigmentsLegend != null)
          assigmentsLegend.classList.add("selected");
        setSelected("assigmentsLegend");
        break;
      default:
        break;
    }
  };

  const goBack = () => {
    if (back == undefined) return;
    back("students");
  };

  const resetExercises = async () => {
    let newQuestions = [];
    const { data, error } = await supabase
      .from("Users_Exercises")
      .select()
      .eq("uid", uid);
    if (error) {
      setErrorMessage(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data) {
      if (data.length > 0) {
        const questions = JSONPARSESTRIGFY(data[0]).exercises[0].questions;
        for (let index = 0; index < questions.length; index++) {
          let element = questions[index];
          let newQuestion = {
            errors: [],
            exerciseID: element.exerciseID,
            finished: false,
            grade: 100,
            hints: [],
            question: element.question,
            questionType: element.questionType,
            reactions: [],
            stepsString: [],
            subject: element.subject,
            timestamps: [],
          };
          console.log(newQuestion);
          newQuestions.push(newQuestion);
        }
      }
    }
    let newExercises = {
      subject: "Math",
      finished: false,
      questions: newQuestions
    };
    console.log(newExercises);
    const { error: updateError } = await supabase
      .from("Users_Exercises")
      .update({ exercises: [newExercises] })
      .eq("uid", uid);
    if (updateError) {
      setErrorMessage(JSONPARSESTRIGFY(updateError).message);
      console.error("Error: " + JSONPARSESTRIGFY(updateError).message);
    }
    // await fetchChildData(uid);
    alert("Exercises reset complete");
  };

  const openSettings = () => {
    if (openSettingsDialog !== undefined) {
      openSettingsDialog();
    }
  };

  return (
    <div>
      {back !== undefined && (
        <button
          className="appButton"
          style={{ marginLeft: "1rem" }}
          onClick={() => goBack()}
        >
          <IoArrowBackOutline />
        </button>
      )}
      <button
        className="appButton"
        style={{ marginLeft: "1rem" }}
        onClick={() => resetExercises()}
      >
        <GrPowerReset className="GrPowerReset" />
      </button>
      {pleaseSelectChildUID ? (
        <p
          className="appButton pleaseSelectChildUIDButton"
          style={{ width: "fit-content" }}
          onClick={() => openSettings()}
        >
          Please select child uid in the settings
        </p>
      ) : (
        <fieldset className="classFieldSet">
          <legend className="legendNoHover">Student information</legend>
          <p>
            <b>{t("KnowBoo.StudentViewPage.FirstName")} </b>
            {firstName}
          </p>
          <p>
            <b>{t("KnowBoo.StudentViewPage.LastName")} </b>
            {lastName}
          </p>
          <p>
            <b>{t("KnowBoo.StudentViewPage.UID")} </b>
            {uid}
          </p>
          <p>
            <b>{t("KnowBoo.StudentViewPage.Email")} </b>
            {email}
          </p>
          <p>
            <b>{t("KnowBoo.StudentViewPage.School")} </b>
            {school}
          </p>
          <p>
            <b>{t("KnowBoo.StudentViewPage.Class")} </b>
            {classroom}
          </p>
          {/* {roomLink != null &&
        roomLink != "" && (
          <Link className="appButton" to={roomLink}>
            Go to student's room
          </Link>
        )} */}
          <fieldset className="fieldSet">
            <div className="legends" style={{ width: "25rem" }}>
              <div
                id="homeworkLegend"
                className="legend selected"
                onClick={() => handleSelection(0)}
              >
                {t("KnowBoo.StudentViewPage.Homework")}
              </div>
              <div
                id="examsLegend"
                className="legend"
                onClick={() => handleSelection(1)}
              >
                {t("KnowBoo.StudentViewPage.Exams")}
              </div>
              <div
                id="exercisesLegend"
                className="legend"
                onClick={() => handleSelection(2)}
              >
                {t("KnowBoo.StudentViewPage.Exercises")}
              </div>
              <div
                id="assigmentsLegend"
                className="legend"
                onClick={() => handleSelection(3)}
              >
                {t("KnowBoo.StudentViewPage.Assignments")}
              </div>
            </div>
            <div className="fieldSetContent">
              {selected == "homeworkLegend" ? (
                <>
                  <p>{t("KnowBoo.StudentViewPage.Homework")}</p>
                  <p>{t("KnowBoo.StudentViewPage.Math")}</p>
                  {homework
                    ? homework.map((data, index) => (
                        <div key={index}>
                          {JSONPARSESTRIGFY(data).finished && (
                            <Exercise data={data} />
                          )}
                        </div>
                      ))
                    : null}
                </>
              ) : selected == "examsLegend" ? (
                <>
                  <p>{t("KnowBoo.StudentViewPage.Exams")}</p>
                  <p>{t("KnowBoo.StudentViewPage.Math")}</p>
                  {exams
                    ? exams.map((data, index) => (
                        <div key={index}>
                          {JSONPARSESTRIGFY(data).finished && (
                            <Exercise data={data} />
                          )}
                        </div>
                      ))
                    : null}
                </>
              ) : selected == "exercisesLegend" ? (
                <>
                  <p>{t("KnowBoo.StudentViewPage.Exercises")}</p>
                  <p>{t("KnowBoo.StudentViewPage.Math")}</p>
                  {exercises
                    ? exercises.map((data, index) => (
                        <div key={index}>
                          {JSONPARSESTRIGFY(data).finished && (
                            <Exercise data={data} />
                          )}
                        </div>
                      ))
                    : null}
                </>
              ) : selected == "assigmentsLegend" ? (
                <>
                  <p>{t("KnowBoo.StudentViewPage.Assignments")}</p>
                  <p>{t("KnowBoo.StudentViewPage.Math")}</p>
                  {assigments
                    ? assigments.map((data, index) => (
                        <div key={index}>
                          {JSONPARSESTRIGFY(data).finished && (
                            <Exercise data={data} />
                          )}
                        </div>
                      ))
                    : null}
                </>
              ) : (
                <p className="error">error</p>
              )}
            </div>
          </fieldset>
        </fieldset>
      )}
    </div>
  );
}

export default StudentViewPage;
