import { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import { AiOutlineEdit, AiOutlineEnter } from "react-icons/ai";
import { createClient } from "@supabase/supabase-js";
import "./StudentComponent.css";
import { JSONPARSESTRIGFY } from "../Utils";

type reactopnProps = {
  uid: string;
  classroom: string;
  modeProp: string;
  onEnter: (studentName: object, type: string) => void;
  updateStudents: () => void;
};

const StudentComponent = ({
  classroom,
  modeProp,
  onEnter,
  uid,
  updateStudents,
}: reactopnProps) => {
  const [mode, setMode] = useState(modeProp);
  const [state, setState] = useState("");
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentUID, setStudentUID] = useState("");
  const [studentSchool, setStudentSchool] = useState("");
  const [studentClassroom, setStudentClassroom] = useState("");
  const [studentRoomLink, setStudentRoomLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    setStudentUID(uid);
    fetchChildData(uid);
  }, [supabase]);

  const fetchChildData = async (studentUIDParam: string) => {
    if (studentUIDParam == "") return;
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", studentUIDParam);
    if (firstNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(firstNameError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(firstNameError).message));
    }
    if (firstNameData != null) {
      if (firstNameData.length > 0) {
        setStudentFirstName(firstNameData[0].firstName);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", studentUIDParam);
    if (lastNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(lastNameError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(lastNameError).message));
    }
    if (lastNameData != null) {
      if (lastNameData.length > 0) {
        setStudentLastName(lastNameData[0].lastName);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", studentUIDParam);
    if (emailError) {
      console.error("Error: " + JSONPARSESTRIGFY(emailError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(emailError).message));
    }
    if (emailData != null) {
      if (emailData.length > 0) {
        setStudentEmail(emailData[0].email);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: classroomData, error: classroomError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", studentUIDParam);
    if (classroomError) {
      console.error("Error: " + JSONPARSESTRIGFY(classroomError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(classroomError).message));
    }
    if (classroomData != null) {
      if (classroomData.length > 0) {
        setStudentClassroom(classroomData[0].class);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: schoolData, error: schoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", studentUIDParam);
    if (schoolError) {
      console.error("Error: " + JSONPARSESTRIGFY(schoolError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(schoolError).message));
    }
    if (schoolData != null) {
      if (schoolData.length > 0) {
        setStudentSchool(schoolData[0].school);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    // setStudentRoomLink(nameData[0].roomLink);
  };

  const updateClassData = async (userID: string) => {
    if (userID == "") {
      alert("Please enter a student ID...");
      return;
    }
    // check if uid exists in students table
    const { data: nameData, error: nameError } = await supabase
      .from("Students")
      .select()
      .eq("uid", userID);
    console.log(nameError);
    console.log(nameData);
    if (nameError) {
      console.error("Error: " + JSONPARSESTRIGFY(nameError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(nameError).message));
    }
    if (nameData != null) {
      if (nameData.length == 0) {
        alert("invalid uid");
        return;
      }
    }
    // get class studentUID array
    const { data: studentUIDSData, error: studentUIDSError } = await supabase
      .from("Classes")
      .select()
      .eq("name", classroom);
    if (studentUIDSData) {
      // update class studentUID array
      const studentsUIDS = JSON.parse(
        JSON.stringify(studentUIDSData[0].studentsUIDS),
      );
      let newStudentsUIDS;
      if (studentsUIDS == null) {
        newStudentsUIDS = [userID];
      } else {
        for (let index = 0; index < studentsUIDS.length; index++) {
          const element = studentsUIDS[index];
          if (element == userID) {
            alert("user allready in class");
            return;
          }
        }
        newStudentsUIDS = [...studentsUIDS, userID];
      }
      const { error: updateError } = await supabase
        .from("Classes")
        .update({ studentsUIDS: newStudentsUIDS })
        .eq("name", classroom);
      if (updateError) {
        console.error(JSONPARSESTRIGFY(updateError).message);
      }
    }
    await fetchChildData(userID);
    setMode("student");
  };

  const deleteUserUpdateClassData = async () => {
    // check if uid exists in students table
    const { data: nameData, error: nameError } = await supabase
      .from("Students")
      .select()
      .eq("uid", studentUID);
    console.log(nameError);
    console.log(nameData);
    if (nameError) {
      console.error("Error: " + JSONPARSESTRIGFY(nameError).message);
      setErrorMessage(JSON.stringify(JSONPARSESTRIGFY(nameError).message));
    }
    if (nameData != null) {
      if (nameData.length == 0) {
        alert("error can't remove student from class");
        return;
      }
    }
    // get class studentUID array
    const { data: studentUIDSData, error: studentUIDSError } = await supabase
      .from("Classes")
      .select()
      .eq("name", classroom);
    if (studentUIDSData) {
      // update class studentUID array
      let studentsUIDS: string[] = JSON.parse(
        JSON.stringify(studentUIDSData[0].studentsUIDS),
      );
      if (studentsUIDS == null) {
        return;
      }
      studentsUIDS = studentsUIDS.filter(
        (student: string) => student !== studentUID,
      );
      const { error: updateError } = await supabase
        .from("Classes")
        .update({ studentsUIDS: studentsUIDS })
        .eq("name", classroom);
      if (updateError) {
        console.error(JSONPARSESTRIGFY(updateError).message);
      }
      updateStudents();
    }
  };

  return (
    <div
      className="appButton"
      onClick={() =>
        onEnter(
          {
            firstName: studentFirstName,
            lastName: studentLastName,
            uid: studentUID,
            roomLink: "",
            email: studentEmail,
            school: studentSchool,
            classroom: studentClassroom,
          },
          "Student",
        )
      }
    >
      <h3>{studentFirstName}</h3>
      {/* <div className="studentComponentContainerButtons">
         <BsTrash
          className="BsTrash"
          onClick={() => deleteUserUpdateClassData()}
        />
         <AiOutlineEnter
          className="AiOutlineEnter"
          onClick={() =>
 
          }
        />
      </div> */}
    </div>
  );
};

export default StudentComponent;
