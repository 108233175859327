import { useState, useEffect } from 'react'
import Book from '../book.png';
import { createClient } from "@supabase/supabase-js";
import Logo from '../Logo_no_beckround.png';
import './AccountComponent.css';
import { uuid } from '@supabase/gotrue-js/dist/module/lib/helpers';
import { JSONPARSESTRIGFY } from '../Utils';

function AccountComponent(props) {
  var emailCheck = require('email-check')
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rule, setRule] = useState("Student");
  const [errorMessaage, setErrorMessage] = useState("");
  const [state, setState] = useState("login");
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [supabase, setSupabase] = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  }

  useEffect(() => {
    if (supabase === undefined) return;
    fetchSchools();
  }, [supabase]);

  const fetchSchools = async () => {
    const { data, error } = await supabase
      .from('Schools')
      .select();
    if (error) {
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data) {
      if (data.length > 0) {
        setSchools(JSONPARSESTRIGFY(data));
        setClasses(JSONPARSESTRIGFY(data[0]).class);
      }
    }
  }

  const handleLogIn = async (e) => {
    e.preventDefault();
    if (email == "") {
      setErrorMessage("Please enter your email");
      return;
    }
    if (password == "") {
      setErrorMessage("Please enter your password");
      return;
    }
    const { data: emailData, error: emailError } = await supabase
      .from('Users_Email')
      .select()
      .eq('email', email);
    if (emailData) {
      if (emailData.length > 0) {
        const fetchUID = JSONPARSESTRIGFY(emailData[0]).uid;
        const { data: authorizedData, error: authorizedError } = await supabase
          .from('Users_Authorized')
          .select()
          .eq('uid', fetchUID);
        if (authorizedData) {
          if (authorizedData.length > 0) {
            const authorized = JSONPARSESTRIGFY(authorizedData[0]).authorized;
            if (authorized) {
              setErrorMessage("Authorized");
            } else {
              setErrorMessage("Not authorized");
              return;
            }
          }
        }
      }
    }
    const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })
    if (signInError != null) {
      switch (JSONPARSESTRIGFY(signInError).message) {
        case "Email not confirmed":
          setErrorMessage("An email was sent to you, please verify");
          return;
        default:
          console.error("Error: " + JSONPARSESTRIGFY(signInError).message);
          setErrorMessage("Make sure the inputs are correct");
          break;
      }
    }
    if (signInData.user) {
      setErrorMessage("");
      props.setUID(signInData.user.id);
      props.setRule(signInData.user.user_metadata.rule);
      props.fetchData(signInData.user.user_metadata.rule, signInData.user.id);
      props.setSelected("Account");
    }
  }

  // const validateEmail = () => {
  //   emailCheck(email)
  //     .then((res) => {
  //       console.log(res);
  //       console.log(email);
  //       return res;
  //     })
  //     .catch((err) => {
  //       if (err.message === 'refuse') {
  //         // The MX server is refusing requests from your IP address.
  //         console.log("MX servers is refusing your ip");
  //       } else {
  //         // Decide what to do with other errors.
  //         console.log(err.message);
  //       }
  //     });
  // }

  const handleState = (state) => {
    setErrorMessage("");
    setState(state);
  }

  const handleSignUp = async (e) => {

    e.preventDefault();

    if (email === "") {
      setErrorMessage("Please enter your email");
      return;
    }

    // if (!validateEmail()) {
    //   setErrorMessage("email is invalid or doesn't exists");
    //   return;
    // }

    if (password === "") {
      setErrorMessage("Please enter your password");
      return;
    }

    if (passwordVerify === "") {
      setErrorMessage("Please verify your password");
      return;
    }

    if (firstName === "") {
      setErrorMessage("Please enter your name");
      return;
    }

    if (password !== passwordVerify) {
      setErrorMessage("Make sure your password and password verification are identical");
      return;
    }

    // const { data: studentFetchData, error: _studentFetchError } = await supabase
    //   .from("Users")
    //   .select()
    //   .eq('email', email)

    // if (studentFetchData) {
    //   if (studentFetchData.length > 0) {
    //     return true;
    //   }
    // }
    // return false;

    if (selectedClass === "") {
      setSelectedSchool(JSONPARSESTRIGFY(schools[0]).schoolName);
    }
    if (selectedClass === "") {
      setSelectedClass(JSONPARSESTRIGFY(schools[0]).class);
    }

    const { data: signUpData, error: signUpError } = await supabase.auth.signUp(
      {
        email: email,
        password: password,
        options: {
          data: {
            rule: rule,
          }
        }
      }
    )

    if (signUpError) {
      console.error(JSONPARSESTRIGFY(signUpError).message);
      setErrorMessage(JSONPARSESTRIGFY(signUpError).message);
    }

    let questionOne = {
      subject: "Math",
      question: "10x=10",
      questionType: "firstOrder",
      stepsString: [],
      hints: [],
      errors: [],
      reactions: [],
      grade: 100,
      timeStamps: [],
      exerciseID: uuid(),
      finished: false,
    };

    let questionTwo = {
      subject: "Math",
      question: "5x+5=10",
      questionType: "firstOrder",
      stepsString: [],
      hints: [],
      errors: [],
      reactions: [],
      grade: 100,
      timeStamps: [],
      exerciseID: uuid(),
      finished: false,
    };

    let questionThree = {
      subject: "Math",
      question: "100x=50+50x",
      questionType: "firstOrder",
      stepsString: [],
      hints: [],
      errors: [],
      reactions: [],
      grade: 100,
      timeStamps: [],
      exerciseID: uuid(),
      finished: false,
    };

    let questionFour = {
      subject: "Math",
      question: "13x=8x-20",
      questionType: "firstOrder",
      stepsString: [],
      hints: [],
      errors: [],
      reactions: [],
      grade: 100,
      timeStamps: [],
      exerciseID: uuid(),
      finished: false,
    };

    let questionFive = {
      subject: "Math",
      question: "60x+30x=60x-30",
      questionType: "firstOrder",
      stepsString: [],
      hints: [],
      errors: [],
      reactions: [],
      grade: 100,
      timeStamps: [],
      exerciseID: uuid(),
      finished: false,
    };

    const exam = {
      subject: "Math",
      finished: false,
      questions: [questionOne, questionTwo, questionThree, questionFour, questionFive],
    };

    if (signUpData.user) {
      const { data: insertEmailData, error: inserEmailError } = await supabase
        .from("Users_Email")
        .insert({ uid: signUpData.user.id, email: email });
      const { data: insertAuthorizedData, error: insertAuthorizedError } = await supabase
        .from("Users_Authorized")
        .insert({ uid: signUpData.user.id, authorized: false });
      const { data: insertClassData, error: insertClassError } = await supabase
        .from("Users_Class")
        .insert({ uid: signUpData.user.id, class: selectedClass });
      const { data: insertSchoolData, error: insertSchoolError } = await supabase
        .from("Users_School")
        .insert({ uid: signUpData.user.id, school: selectedSchool });
      const { data: insertFirstNameData, error: insertFirstNameError } = await supabase
        .from("Users_FirstName")
        .insert({ uid: signUpData.user.id, firstName: firstName });
      const { data: insertLastNameData, error: insertLastNameError } = await supabase
        .from("Users_LastName")
        .insert({ uid: signUpData.user.id, lastName: lastName });
      const { data: insertRuleData, error: insertRuleError } = await supabase
        .from("Users_Rule")
        .insert({ uid: signUpData.user.id, rule: rule });
      if (signUpData.user.user_metadata.rule === "Student") {
        const { data: insertExercisesData, error: insertExercisesError } = await supabase
          .from("Users_Exercises")
          .insert({ uid: signUpData.user.id, exercises: [questionOne, questionTwo, questionThree, questionFour, questionFive] });
        const { data: insertExamsData, error: insertExamsError } = await supabase
          .from("Users_Exams")
          .insert({ uid: signUpData.user.id, exams: [exam] });
        const { data: insertAssigmentsData, error: insertAssigmentsError } = await supabase
          .from("Users_Assigments")
          .insert({ uid: signUpData.user.id, assigments: [exam] });
        const { data: insertHomeworkData, error: insertHomeworkError } = await supabase
          .from("Users_Homework")
          .insert({ uid: signUpData.user.id, homework: [exam] });
      }
      setErrorMessage("Sign up successful!");
    }
  }

  return (
    <div className='accountContainer'>
      {/* <img className=" h-28 w-auto justify-self-center" src={Logo} alt="logo" /> */}
      <div className="anim-typewriter">
        {state == "login" ?
          <h2 className='h2Text'>Please Login</h2>
          :
          <h2 className='h2Text'>Please Sign Up</h2>
        }
      </div>
      <label htmlFor="email">✉️Email✉️</label>
      <input id="email" className='accountInput' type="email" value={email} placeholder='Enter your email here...' onChange={(e) => setEmail(e.target.value)} />
      <label htmlFor="password">🔑Password🔑</label>
      <input id="password" className='accountInput' type="password" value={password} placeholder='Enter your password here...' onChange={(e) => setPassword(e.target.value)} />
      {state == "signup" ?
        <>
          <label htmlFor="passwordVerify">🔑Password verification🔑</label>
          <input id="passwordVerify" className='accountInput' type="password" value={passwordVerify} placeholder='Verify your password...' onChange={(e) => setPasswordVerify(e.target.value)} />
          <label htmlFor="firstName">🙋First name🙋</label>
          <input id="firstName" className='accountInput' type="text" value={firstName} placeholder='Enter your first name here...' onChange={(e) => setFirstName(e.target.value)} />
          <label htmlFor="lastName">🙋Last name🙋</label>
          <input id="lastName" className='accountInput' type="text" value={lastName} placeholder='Enter your last name here...' onChange={(e) => setLastName(e.target.value)} />
          <label htmlFor="selectRule">📋Select Rule📋</label>
          {/* <select id="selectRule" className='selectRule' onChange={(e) => setRule(e.target.value)}>
            <option value="Student">Student</option>
            <option value="Teacher">Teacher</option>
            <option value="Parent">Parent</option>
            <option value="Manager">Manager</option>
            <option value="Admin">Admin</option>
          </select> */}
          <div className='settingsContainer'>
            <label htmlFor="schools">Your school: </label>
            <select name="schools" id="schools" className="selectSettings" onChange={(e) => setSelectedSchool(e.target.value)}>
              {schools
                .map((data, index) => (
                  <option key={index} value={JSONPARSESTRIGFY(data).schoolName} onClick={() => setClasses(schools[index].class)}>{JSONPARSESTRIGFY(data).schoolName}</option>
                ))}
            </select>
          </div>
          <div className='settingsContainer'>
            <label htmlFor="classes">Your class: </label>
            <select name="classes" id="classes" className="selectSettings" onChange={(e) => setSelectedClass(e.target.value)}>
              {classes
                .map((data, index) => (
                  <option key={index} value={data}>{data}</option>
                ))}
            </select>
          </div>
        </>
        :
        null
      }
      {errorMessaage && (
        <h5 className='error'>{errorMessaage}</h5>
      )}
      {state == "login" ?
        <button className='appButton mt-5' onClick={(e) => handleLogIn(e)}>Login</button>
        :
        <button className='appButton mt-5' onClick={(e) => handleSignUp(e)}>Sign Up</button>
      }
      <div className="anim-typewriter">
        {state == "login" ?
          <button className='accountLink' onClick={() => handleState("signup")}>Don't have an account?</button>
          :
          <button className='accountLink' onClick={() => handleState("login")}>Allready have an account?</button>
        }
      </div>
    </div>
  )
}

export default AccountComponent;
