import { useState, useEffect } from "react";
import { JSONPARSESTRIGFY } from "../Utils";
import { createClient } from "@supabase/supabase-js";
import ExercisesList from "../Components/ExercisesList/ExercisesList";
import StudentViewPage from "./StudentViewPage";
import "./StudentPage.css";

type props = {
  onEnter?: () => void;
};

function StudentPage({ onEnter }: props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setUID] = useState("");
  const [userSchool, setUserSchool] = useState("");
  const [userClass, setUserClass] = useState("");
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    supabase.auth.getSession().then((data: any) => {
      const session = data.data.session;
      if (session != null) {
        setUID(session.user.id);
        fetchData(session.user.id);
      }
    });
  }, [supabase]);

  const fetchData = async (userID: string) => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", userID);
    if (firstNameError) {
      console.error("error: " + JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData) {
      setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", userID);
    if (lastNameError) {
      console.error("error: " + JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData) {
      setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", userID);
    if (emailError) {
      console.error("error: " + JSONPARSESTRIGFY(emailError).message);
    }
    if (emailData) {
      setEmail(JSONPARSESTRIGFY(emailData[0]).email);
    }
    const { data: schoolData, error: schoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", userID);
    if (schoolError) {
      console.error("error: " + JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData) {
      setUserSchool(JSONPARSESTRIGFY(schoolData[0]).school);
    }
    const { data: classData, error: classError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", userID);
    if (classError) {
      console.error("error: " + JSONPARSESTRIGFY(classError).message);
    }
    if (classData) {
      if (JSONPARSESTRIGFY(classData[0]).class !== null) {
        setUserClass(JSONPARSESTRIGFY(classData[0]).class);
      }
    }
  };

  const enter = () => {
    if (onEnter == null) return;
    onEnter();
  };

  return (
    <>
      <button className="appButton excalidrawButton" onClick={() => enter()}>
        go to exaclidraw!
      </button>
      <StudentViewPage
        firstName={firstName}
        lastName={lastName}
        uid={uid}
        roomLink={""}
        email={email}
        school={userSchool}
        classroom={userClass}
      />
    </>
  );
}

export default StudentPage;
