import React from "react";

interface noteProps{
    title: string,
    content: string,
}

function Article(noteProps: noteProps) {
  return (
    <div className=' align-text-bottom px-1 py-1 bg-white rounded-md h-24  flex-auto shadow-lg'>
      <h1 className="  text-left font-sans bottom-2 text-blue-800 font-bold text-base">{noteProps.title}</h1>
      <p className="text-left-start font-sans text-black-700/40 text-sm">{noteProps.content}</p>
    </div>
  );
}

export default Article;