import { useState, useEffect } from 'react';
import StudentViewPage from '../Student/StudentViewPage';
import { createClient } from "@supabase/supabase-js";
import { JSONPARSESTRIGFY } from '../Utils';
import { t } from "../../i18n";
import "./Parent.css";

function ParentPage(props) {
  const [uid, setUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userSchool, setUserSchool] = useState("");
  const [userClass, setUserClass] = useState("");
  //----------------------------------------------------------------
  const [childUID, setChildUID] = useState("none");
  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [childEmail, setChildEmail] = useState("");
  const [childSchool, setChildSchool] = useState("");
  const [childClass, setChildClass] = useState("");
  const [childRoomLink, setChildRoomLink] = useState("");
  //---------------------------------------------------------
  const [errorMessage, setErrorMessage] = useState(null);
  const [supabase, setSupabase] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  }

  useEffect(() => {
    if (supabase === undefined) return;
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session != null) {
        setUID(session.user.id);
        fetchUserData(session.user.id);
      }
    })
  }, [supabase]);


  const fetchUserData = async (userID) => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", userID);
    if (firstNameError) {
      console.error("error: " + JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData) {
      if (firstNameData.length > 0) {
        setFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", userID);
    if (lastNameError) {
      console.error("error: " + JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData) {
      if (lastNameData.length > 0) {
        setLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
      }
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", userID);
    if (emailError) {
      console.error("error: " + JSONPARSESTRIGFY(emailError).message);
    }
    if (emailData) {
      if (emailData.length > 0) {
        setEmail(JSONPARSESTRIGFY(emailData[0]).email);
      }
    }
    const { data: schoolData, error: schoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", userID);
    if (schoolError) {
      console.error("error: " + JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData) {
      if (schoolData.length > 0) {
        setUserSchool(JSONPARSESTRIGFY(schoolData[0]).school);
      }
    }
    const { data: classData, error: classError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", userID);
    if (classError) {
      console.error("error: " + JSONPARSESTRIGFY(classError).message);
    }
    if (classData) {
      if (classData.length > 0) {
        if (JSONPARSESTRIGFY(classData[0]).class !== null) {
          setUserClass(JSONPARSESTRIGFY(classData[0]).class);
        }
      }
    }
    const { data: studentUIDData, error: studentUIDError } = await supabase
      .from("Parents_ChildUID")
      .select()
      .eq("uid", userID);
    if (studentUIDError) {
      console.error("error: " + JSONPARSESTRIGFY(studentUIDError).message);
    }
    if (studentUIDData) {
      if (studentUIDData.length > 0) {
        if (JSONPARSESTRIGFY(studentUIDData[0]).childUID !== undefined) {
          setChildUID(JSONPARSESTRIGFY(studentUIDData[0]).childUID);
          fetchChildData(JSONPARSESTRIGFY(studentUIDData[0]).childUID);
        }
      }
    }
  };

  const fetchChildData = async (userID) => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", userID);
    if (firstNameError) {
      console.error("error: " + JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData) {
      if (firstNameData.length > 0) {
        setChildFirstName(JSONPARSESTRIGFY(firstNameData[0]).firstName);
        console.log(JSONPARSESTRIGFY(firstNameData[0]).firstName);
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", userID);
    if (lastNameError) {
      console.error("error: " + JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData) {
      if (lastNameData.length > 0) {
        setChildLastName(JSONPARSESTRIGFY(lastNameData[0]).lastName);
        console.log(JSONPARSESTRIGFY(lastNameData[0]).lastName);
      }
    }
    const { data: emailData, error: emailError } = await supabase
      .from("Users_Email")
      .select()
      .eq("uid", userID);
    if (emailError) {
      console.error("error: " + JSONPARSESTRIGFY(emailError).message);
    }
    if (emailData) {
      if (emailData.length > 0) {
        setChildEmail(JSONPARSESTRIGFY(emailData[0]).email);
        console.log(JSONPARSESTRIGFY(emailData[0]).email);
      }
    }
    const { data: schoolData, error: schoolError } = await supabase
      .from("Users_School")
      .select()
      .eq("uid", userID);
    if (schoolError) {
      console.error("error: " + JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData) {
      if (schoolData.length > 0) {
        setChildSchool(JSONPARSESTRIGFY(schoolData[0]).school);
        console.log(JSONPARSESTRIGFY(schoolData[0]).school);
      }
    }
    const { data: classData, error: classError } = await supabase
      .from("Users_Class")
      .select()
      .eq("uid", userID);
    if (classError) {
      console.error("error: " + JSONPARSESTRIGFY(classError).message);
    }
    if (classData) {
      if (classData.length > 0) {
        setChildClass(JSONPARSESTRIGFY(classData[0]).class);
        console.log(JSONPARSESTRIGFY(classData[0]).class);
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <fieldset className="classFieldSet">
        <legend className="legendNoHover">Your information</legend>
        <p>
          <b>{t("KnowBoo.StudentViewPage.FirstName")} </b>
          {firstName}
        </p>
        <p>
          <b>{t("KnowBoo.StudentViewPage.LastName")} </b>
          {lastName}
        </p>
        <p>
          <b>{t("KnowBoo.StudentViewPage.UID")} </b>
          {uid}
        </p>
        <p>
          <b>{t("KnowBoo.StudentViewPage.Email")} </b>
          {email}
        </p>
        <p>
          <b>{t("KnowBoo.StudentViewPage.School")} </b>
          {userSchool}
        </p>
        <p>
          <b>{t("KnowBoo.StudentViewPage.Class")} </b>
          {userClass}
        </p>
      </fieldset>
      {loading ?
        <p className='loading'>loading...</p>
        :
        <StudentViewPage
          openSettingsDialog={props.openSettingsDialog}
          firstName={childFirstName}
          lastName={childLastName}
          uid={childUID}
          roomLink={childRoomLink}
          email={childEmail}
          school={childSchool}
          classroom={childClass}
        />
      }
    </div>
  )
}

export default ParentPage;
