import { useState, useEffect } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { JSONPARSESTRIGFY } from '../Utils';
import { createClient } from "@supabase/supabase-js";
import Class from '../Class/ClassComponent';
import './TeacherPage.css';
import ClassPage from '../Class/ClassPage';
import { t } from "../../i18n";

function TeacherPage(props) {
  const [school, setSchool] = useState("");
  const [classes, setClasses] = useStateWithCallbackLazy([]);
  const [uid, setUID] = useState("");
  const [teacherErrorMessage, setTeacherErrorMessage] = useState(null);
  const [classesErrorMessage, setClassesErrorMessage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentClass, setCurrentClass] = useState("");
  const [supabase, setSupabase] = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  }

  useEffect(() => {
    if (supabase === undefined) return;
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session != null) {
        setUID(session.user.id);
        fetchTeacherData(session.user.id);
      }
    });
  }, [supabase]);

  const fetchTeacherData = async (userID) => {
    const { data: firstNameData, error: firstNameError } = await supabase.from("Users_FirstName").select().eq('uid', userID);
    if (firstNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(firstNameError).message);
      setTeacherErrorMessage(JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData != null) {
      if (firstNameData.length > 0) {
        setFirstName(firstNameData[0].firstName);
        setTeacherErrorMessage(null);
      } else {
        console.error("Error: Can't get teacher data");
        setTeacherErrorMessage("Error: Can't get teacher data");
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase.from("Users_LastName").select().eq('uid', userID);
    if (lastNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(lastNameError).message);
      setTeacherErrorMessage(JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData != null) {
      if (lastNameData.length > 0) {
        setLastName(lastNameData[0].lastName);
        setTeacherErrorMessage(null);
      } else {
        console.error("Error: Can't get teacher data");
        setTeacherErrorMessage("Error: Can't get teacher data");
      }
    }
    const { data: schoolData, error: schoolError } = await supabase.from("Users_School").select().eq('uid', userID);
    if (lastNameError) {
      console.schoolError("Error: " + JSONPARSESTRIGFY(schoolError).message);
      setTeacherErrorMessage(JSONPARSESTRIGFY(schoolError).message);
    }
    if (schoolData != null) {
      if (schoolData.length > 0) {
        setSchool(schoolData[0].school);
        fetchClasses(schoolData[0].school);
        setTeacherErrorMessage(null);
      } else {
        console.error("Error: Can't get teacher data");
        setTeacherErrorMessage("Error: Can't get teacher data");
      }
    }
  }

  const fetchClasses = async (schoolName) => {
    const { data: teacherSchoolData, error: teacherSchoolError } = await supabase.from("Schools").select().eq('schoolName', schoolName);
    if (teacherSchoolError) {
      console.error("Error: " + JSONPARSESTRIGFY(teacherSchoolError).message);
      setClassesErrorMessage(JSONPARSESTRIGFY(teacherSchoolError).message);
    }
    if (teacherSchoolData != null) {
      if (teacherSchoolData.length > 0) {
        if (JSONPARSESTRIGFY(teacherSchoolData[0]).classes != null) {
          setClasses(JSONPARSESTRIGFY(teacherSchoolData[0]).classes);
          setClassesErrorMessage(null);
        } else {
          console.error("Error: Can't get teacher classes");
          setClassesErrorMessage("Error: Can't get teacher classes");
        }
      }
    }
  }

  const handleCurrentClass = async (className) => {
    setCurrentClass(className);
    setClasses([], () => {
      fetchClasses(school);
    });
  }

  return (
    <div>
      {currentClass != "" ?
        <ClassPage classroom={currentClass} back={() => setCurrentClass("")} school={school} />
        :
        <fieldset className='classFieldSet'>
          <legend className='legendNoHover'>{t("KnowBoo.TeacherPage.Classes")}:</legend>
          <div className='classesContainer'>
            {
              classes.length > 0 ?
                classes.map((data, index) => (
                  <Class key={index} stateProp="show" uid={uid} name={data} onEnter={(classroom) => handleCurrentClass(classroom)} />
                ))
                :
                <p>{classesErrorMessage}</p>
            }
          </div>
        </fieldset>
      }
    </div>
  )
}

export default TeacherPage;
