import { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { JSONPARSESTRIGFY } from "../Utils";
import Parent from "../Parent/ParentComponent";
import Student from "../Student/StudentButton";
import Teacher from "../Teacher/TeacherComponent";
import ParentViewPage from "../Parent/ParentViewPage";
import StudentViewPage from "../Student/StudentViewPage";
import TeacherViewPage from "../Teacher/TeacherViewPage";
import AuthorizedUser from "./AuthorizedUser";
import "./AdminPage.css";
import { SupabaseClient } from "@supabase/supabase-js";

function AdminPage(props: any) {
  const [uid, setUID] = useState("");
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageStudents, setErrorMessageStudents] = useState("");
  const [errorMessageParents, setErrorMessageParents] = useState("");
  const [errorMessageTeachers, setErrorMessageTeachers] = useState("");
  const [selected, setSelected] = useState("students");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentUserFirstName, setCurrentUserFirstName] = useState("");
  const [currentUserLastName, setCurrentUserLastName] = useState("");
  const [currentUserUID, setCurrentUserUID] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [currentUserRoomLink, setCurrentUserRoomLink] = useState("");
  const [currentUserType, setCurrentUserType] = useState("");
  const [currentUserStudentUID, setCurrentUserStudentUID] = useState("");
  const [currentUserSchool, setCurrentUserSchool] = useState("");
  const [currentUserClassRoom, setCurrentUserClassRoom] = useState("");
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    fetchAllStudents();
    fetchAllParents();
    fetchAllTeachers();
    fetchAuthorizedUsers();
    supabase.auth.getSession().then((data: any) => {
      const session = data.data.session;
      if (session != null) {
        setUID(session.user.id);
        fetchAdminName(session.user.id);
      }
    });
  }, [supabase]);

  const fetchAdminName = async (userID: string) => {
    const { data: firstNameData, error: firstNameError } = await supabase
      .from("Users_FirstName")
      .select()
      .eq("uid", userID);
    if (firstNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(firstNameError).message);
      setErrorMessage(JSONPARSESTRIGFY(firstNameError).message);
    }
    if (firstNameData != null) {
      if (firstNameData.length > 0) {
        setFirstName(firstNameData[0].firstName);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase
      .from("Users_LastName")
      .select()
      .eq("uid", userID);
    if (lastNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(lastNameError).message);
      setErrorMessage(JSONPARSESTRIGFY(lastNameError).message);
    }
    if (lastNameData != null) {
      if (lastNameData.length > 0) {
        setLastName(lastNameData[0].lastName);
        setErrorMessage("");
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  };

  const fetchAllStudents = async () => {
    const { data, error } = await supabase
      .from("Users_Rule")
      .select()
      .eq("rule", "Student");
    if (error) {
      setErrorMessageStudents(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setStudents(JSONPARSESTRIGFY(data));
        setErrorMessageStudents("");
      } else {
        setStudents([]);
        setErrorMessageStudents("Error: Can't get students data");
        console.error("Error: Can't get students data");
      }
    }
  };

  const fetchAllParents = async () => {
    const { data, error } = await supabase
      .from("Users_Rule")
      .select()
      .eq("rule", "Parent");
    if (error) {
      setErrorMessageParents(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setParents(JSONPARSESTRIGFY(data));
        setErrorMessageParents("");
      } else {
        setParents([]);
        setErrorMessageParents("Error: Can't get parents data");
        console.error("Error: Can't get parents data");
      }
    }
  };

  const fetchAllTeachers = async () => {
    const { data, error } = await supabase
      .from("Users_Rule")
      .select()
      .eq("rule", "Teacher");
    if (error) {
      setErrorMessageTeachers(JSONPARSESTRIGFY(error).message);
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setTeachers(JSONPARSESTRIGFY(data));
        setErrorMessageTeachers("");
      } else {
        setTeachers([]);
        setErrorMessageTeachers("Error: Can't get teachers data");
        console.error("Error: Can't get teachers data");
      }
    }
  };

  const fetchAuthorizedUsers = async () => {
    const { data, error } = await supabase
      .from("Users_Authorized")
      .select()
      .eq("authorized", true);
    if (error) {
      setErrorMessage(JSONPARSESTRIGFY(error).message);
      console.error(JSONPARSESTRIGFY(error).message);
    }
    if (data != null) {
      if (data.length > 0) {
        setAuthorizedUsers(JSONPARSESTRIGFY(data));
        setErrorMessage("");
      } else {
        setAuthorizedUsers([]);
        setErrorMessage("Error: Can't get authorized users data");
        console.error("Error: Can't get authorized users data");
      }
    }
  };

  const handleSelection = (num: number) => {
    const studentsLegend = document.getElementById("studentsLegend");
    const parentsLegend = document.getElementById("parentsLegend");
    const teachersLegend = document.getElementById("teachersLegend");
    const authorizedUsersLegend = document.getElementById(
      "authorizedUsersLegend",
    );

    if (studentsLegend) {
      studentsLegend.classList.remove("selected");
    }

    if (parentsLegend) {
      parentsLegend.classList.remove("selected");
    }

    if (teachersLegend) {
      teachersLegend.classList.remove("selected");
    }

    if (authorizedUsersLegend) {
      authorizedUsersLegend.classList.remove("selected");
    }

    switch (num) {
      case 0:
        if (studentsLegend) {
          studentsLegend.classList.add("selected");
        }
        setSelected("students");
        break;
      case 1:
        if (parentsLegend) {
          parentsLegend.classList.add("selected");
        }
        setSelected("parents");
        break;
      case 2:
        if (teachersLegend) {
          teachersLegend.classList.add("selected");
        }
        setSelected("teachers");
        break;
      case 3:
        if (authorizedUsersLegend) {
          authorizedUsersLegend.classList.add("selected");
        }
        setSelected("authorizedUsers");
        break;
      default:
        break;
    }
  };

  const handleCurrentUser = (data: any, type: string) => {
    console.log(data);
    setCurrentUserFirstName(data.firstName);
    setCurrentUserLastName(data.lastName);
    setCurrentUserUID(data.uid);
    setCurrentUserEmail(data.email);
    setCurrentUserSchool(data.school);
    setCurrentUserClassRoom(data.classroom);
    if (data.roomLink != null) {
      setCurrentUserRoomLink(data.roomLink);
    }
    if (data.studentUID != null) {
      setCurrentUserStudentUID(data.studentUID);
    }
    setCurrentUserType(type);
  };

  const clearCurrentUser = (type: string) => {
    setCurrentUserFirstName("");
    setCurrentUserLastName("");
    setCurrentUserUID("");
    setCurrentUserEmail("");
    setCurrentUserRoomLink("");
    setCurrentUserType("");
    setCurrentUserStudentUID("");
    setCurrentUserSchool("");
    setCurrentUserClassRoom("");
    setSelected(type);
  };

  const addAuthorizedUser = async () => {
    let authorizedUserEmailPrompt = prompt("Please enter email");

    if (authorizedUserEmailPrompt != null) {
      const { error } = await supabase
        .from("Users_Authorized")
        .update({ authorized: true })
        .eq("email", authorizedUserEmailPrompt);
      if (error) {
        console.error(JSONPARSESTRIGFY(error).message);
        setErrorMessage(JSONPARSESTRIGFY(error).message);
      } else {
        alert("Email added to authorized users list!");
        fetchAuthorizedUsers();
      }
    }
  };

  return (
    <div>
      {currentUserType !== "" ? (
        <>
          {currentUserType === "Teacher" ? (
            <TeacherViewPage
              back={(type: string) => clearCurrentUser(type)}
              firstName={currentUserFirstName}
              lastName={currentUserLastName}
              uid={currentUserUID}
              email={currentUserEmail}
              school={currentUserSchool}
              classroom={currentUserClassRoom}
            />
          ) : currentUserType === "Parent" ? (
            <ParentViewPage
              back={(type: string) => clearCurrentUser(type)}
              firstName={currentUserFirstName}
              lastName={currentUserLastName}
              uid={currentUserUID}
              studentUID={currentUserStudentUID}
              email={currentUserEmail}
              school={currentUserSchool}
              classroom={currentUserClassRoom}
            />
          ) : currentUserType === "Student" ? (
            <StudentViewPage
              back={(type: string) => clearCurrentUser(type)}
              firstName={currentUserFirstName}
              lastName={currentUserLastName}
              uid={currentUserUID}
              roomLink={currentUserRoomLink}
              email={currentUserEmail}
              school={currentUserSchool}
              classroom={currentUserClassRoom}
            />
          ) : null}
        </>
      ) : (
        <fieldset className="classFieldSet">
          <div className="legends">
            {selected === "students" ? (
              <div
                id="studentsLegend"
                className="legend selected"
                onClick={() => handleSelection(0)}
              >
                Students:
              </div>
            ) : (
              <div
                id="studentsLegend"
                className="legend"
                onClick={() => handleSelection(0)}
              >
                Students:
              </div>
            )}
            {selected === "parents" ? (
              <div
                id="parentsLegend"
                className="legend selected"
                onClick={() => handleSelection(1)}
              >
                Parents:
              </div>
            ) : (
              <div
                id="parentsLegend"
                className="legend"
                onClick={() => handleSelection(1)}
              >
                Parents:
              </div>
            )}
            {selected === "teachers" ? (
              <div
                id="teachersLegend"
                className="legend selected"
                onClick={() => handleSelection(2)}
              >
                Teachers:
              </div>
            ) : (
              <div
                id="teachersLegend"
                className="legend"
                onClick={() => handleSelection(2)}
              >
                Teachers:
              </div>
            )}
            {selected === "authorizedUsers" ? (
              <div
                id="authorizedUsersLegend"
                className="legend selected"
                onClick={() => handleSelection(3)}
              >
                Authorized:
              </div>
            ) : (
              <div
                id="authorizedUsersLegend"
                className="legend"
                onClick={() => handleSelection(3)}
              >
                Authorized:
              </div>
            )}
          </div>
          <input className='searchBar' type="text" value={filter} placeholder='Search user here...' onChange={(e) => setFilter(e.target.value)} />
          {filter !== "" &&<p style={{margin: "1rem"}}>Searching for all users that contain: "{filter}"</p>}
          {selected == "students" ? (
            students ? (
              students.map((data, index) => (
                <Student
                  key={index}
                  filter={filter}
                  uid={JSONPARSESTRIGFY(data).uid}
                  onEnter={(userData, type) =>
                    handleCurrentUser(userData, type)
                  }
                />
              ))
            ) : (
              <p className="error">{errorMessageStudents}</p>
            )
          ) : selected == "parents" ? (
            parents ? (
              parents.map((data, index) => (
                <Parent
                  key={index}
                  filter={filter}
                  uid={JSONPARSESTRIGFY(data).uid}
                  onEnter={(userData, type) =>
                    handleCurrentUser(userData, type)
                  }
                />
              ))
            ) : (
              <p className="error">{errorMessageParents}</p>
            )
          ) : selected == "teachers" ? (
            teachers ? (
              teachers.map((data, index) => (
                <Teacher
                  key={index}
                  filter={filter}
                  uid={JSONPARSESTRIGFY(data).uid}
                  onEnter={(userData, type) =>
                    handleCurrentUser(userData, type)
                  }
                />
              ))
            ) : (
              <p className="error">{errorMessageTeachers}</p>
            )
          ) : authorizedUsers ? (
            <div style={{ marginTop: "2rem" }}>
              {authorizedUsers.map((data, index) => (
                <AuthorizedUser
                  uid={JSONPARSESTRIGFY(data).uid}
                  filter={filter}
                  key={index}
                  reload={() => fetchAuthorizedUsers()}
                />
              ))}
              <button className="appButton" onClick={() => addAuthorizedUser()}>
                Add authorized user
              </button>
            </div>
          ) : (
            <p className="error">{errorMessageTeachers}</p>
          )}
        </fieldset>
      )}
    </div>
  );
}

export default AdminPage;
