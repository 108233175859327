import { useState, useEffect } from "react";
import { t } from "../../i18n";
import "./HintPopUp.css";
import {
  defaultLang,
  getLanguage,
  languages,
  setLanguage,
  t as tLayerUI,
} from "../../i18n";
import CalcSad from "../calcSad.mp4";
import CalcHappy from "../calcHappy.mp4";
import { DISPLAYERROR } from "../Utils";
import "../Components/ExercisesList/ExercisesList.css";

type reactopnProps = {
  onClose: () => void;
  reactionToShow: string;
  hintType: string;
};

const HintPopUp = ({ onClose, reactionToShow, hintType }: reactopnProps) => {
  console.log(hintType);
  const [translatedReaction, setTranslatedReaction] = useState(reactionToShow);
  
  useEffect(() => {
    try {
      setTranslatedReaction(t(reactionToShow));
    } catch (e: any) {
      console.log(hintType);
      DISPLAYERROR(e);
    }
  }, []);

  return (
    <>
      {hintType === "positive" ? (
        <div className="hintPopUpContainer coolBGHintPositive">
          <div className="coolBGeffectHint">
            <b className="reactionToShowText">{translatedReaction}</b>
            <button
              className="closeButtonHint appButton"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      ) : hintType === "neutral" ? (
        <div className="hintPopUpContainer coolBGHintNeutral">
          <div className="coolBGeffectHint">
            <b className="reactionToShowText">{translatedReaction}</b>
            <button
              className="closeButtonHint appButton"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      ) : hintType === "negative" ? (
        <div className="hintPopUpContainer coolBGHintNegative">
          <div className="coolBGeffectHint">
            <b className="reactionToShowText">{translatedReaction}</b>
            <button
              className="closeButtonHint appButton"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      ) : hintType === "neutral" ? (
        <div className="hintPopUpContainer coolBGHintNeutral">
          <div className="coolBGeffectHint">
            <b className="reactionToShowText">{translatedReaction}</b>
            <button
              className="closeButtonHint appButton"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="hintPopUpContainer coolBGHintNeutral">
          <div className="coolBGeffectHint">
            <b className="reactionToShowText">{translatedReaction}</b>
            <button
              className="closeButtonHint appButton"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HintPopUp;

// mascot to add back in the future maybe

/* {type == "positive" ? (
<video
  autoPlay
  loop
  muted
  playsInline
  className="mascot"
  src={CalcHappy}
/>
) : type == "negative" ? (
<video
  autoPlay
  loop
  muted
  playsInline
  className="mascot"
  src={CalcSad}
/>
) : null} */
