import { useState, useEffect } from 'react';
import { JSONPARSESTRIGFY } from '../Utils';
import { createClient } from "@supabase/supabase-js";
import './StudentPopUp.css';
import CalcSad from "../calcSad.mp4";
import CalcHappy from "../calcHappy.mp4";

function StudentPopUp(props) {
  const [uid, setUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showTimeSetting, setShowTimeSetting] = useState(false);
  const [supabase, setSupabase] = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session != null) {
        setUID(session.user.id);
        fetchStudentData(session.user.id);
      }
    });
  }, [supabase]);


  useEffect(() => {
    props.onChangeSettings(showTimeSetting);
  }, [showTimeSetting]);


  const fetchStudentData = async (userID) => {
    const { data: firstNameData, error: firstNameError } = await supabase.from("Users_FirstName").select().eq('uid', userID);
    if (firstNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(firstNameError).message);
      setErrorMessage(firstNameError);
    }
    if (firstNameData != null) {
      if (firstNameData.length > 0) {
        setFirstName(firstNameData[0].firstName);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: lastNameData, error: lastNameError } = await supabase.from("Users_LastName").select().eq('uid', userID);
    if (lastNameError) {
      console.error("Error: " + JSONPARSESTRIGFY(lastNameError).message);
      setErrorMessage(lastNameError);
    }
    if (lastNameData != null) {
      if (lastNameData.length > 0) {
        setLastName(lastNameData[0].lastName);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
    const { data: emailData, error: emailError } = await supabase.from("Users_Email").select().eq('uid', userID);
    if (emailError) {
      console.error("Error: " + JSONPARSESTRIGFY(emailError).message);
      setErrorMessage(emailError);
    }
    if (emailData != null) {
      if (emailData.length > 0) {
        setEmail(emailData[0].email);
        setErrorMessage(null);
      } else {
        console.error("Error: Can't get data");
        setErrorMessage("Error: Can't get data");
      }
    }
  }

  const saveName = async () => {
    const { error: FirstNameError } = await supabase
      .from('Users_FirstName')
      .update({ firstName: firstName })
      .eq('uid', uid);
    if (FirstNameError) {
      setErrorMessage("Error updating name " + JSONPARSESTRIGFY(FirstNameError).message);
      console.error("Error updating name " + JSONPARSESTRIGFY(FirstNameError).message);
    }
    const { error: LastNameError } = await supabase
      .from('Users_LastName')
      .update({ lastName: lastName })
      .eq('uid', uid);
    if (LastNameError) {
      setErrorMessage("Error updating name " + JSONPARSESTRIGFY(LastNameError).message);
      console.error("Error updating name " + JSONPARSESTRIGFY(LastNameError).message);
    }
    setErrorMessage("Updated data");
  }

  const copyUIDToClipBoard = () => {
    navigator.clipboard.writeText(uid);
    alert("UID Copied " + uid);
  }

  const sendPasswordReset = async () => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(
      email,
    )
    if (error) {
      console.error("Error: " + JSONPARSESTRIGFY(error).message);
      setErrorMessage(error);
    }
    alert("Password recorvery email sent...");
  }


  return (
    <div className='coolBG'>
      <div className="chatHeader">
        <button className="specialCloseChatButton" onClick={props.onClose}>
          X
        </button>
        <p>Change your name and copy your UID!</p>
        {/* <video
          autoPlay
          loop
          muted
          playsInline
          className="mascot"
          src={CalcHappy}
        /> */}
      </div>
      <div className='coolBGeffect'>
        <div className='studentContainer'>
          <h2 className='studentPopUpTitle'>Student's personal info area</h2>
          <div className='studentArea'>
            {firstName && <h3>Welcome {firstName}</h3>}
            <label htmlFor="firstName">Your first name</label>
            <input id="firstName" type='text' placeholder='Enter your first name...' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <label htmlFor="lastName">Your last name</label>
            <input id="lastName" type='text' placeholder='Enter your last name...' value={lastName} onChange={(e) => setLastName(e.target.value)} />
            {errorMessage && <p>{errorMessage}</p>}
            {/* <label htmlFor="showTime">Show time on hints</label>
            <input id="showTime" type='checkbox' onChange={(e) => setShowTimeSetting(e.target.checked)}/> */}
            <button className='appButton' onClick={() => saveName()}>Save name</button>
            <button className='appButton' onClick={() => copyUIDToClipBoard()}>copy UID</button>
            <button className='appButton' onClick={() => sendPasswordReset()}>Reset password</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentPopUp;
