import { useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  MessageModel,
} from "@chatscope/chat-ui-kit-react";
import { MessageDirection } from "@chatscope/chat-ui-kit-react/src/types/unions";
import "./Chat.css";
import CalcSad from "../calcSad.mp4";
import CalcHappy from "../calcHappy.mp4";

type reactopnProps = {
  setTypingInChat: (state: boolean) => void;
  onClose: () => void;
};

const Chat = ({ setTypingInChat, onClose }: reactopnProps) => {
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Hello, I am ChatGPT! how can i assist you?",
      sender: "ChatGPT",
      direction: "incoming" as MessageDirection,
      position: "normal" as const,
    },
  ]);

  const handleSend = async (message: string) => {
    const newMessage = {
      message: message,
      sender: "user",
      direction: "outgoing" as MessageDirection,
      position: "normal" as const,
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages: any[]) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const systemMessage = {
      role: "system",
      content: "Explain all concepts like I am 10 years old.",
    };

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    };

    await fetch("https://knowboo-a95386b70b9e.herokuapp.com/chatGPT", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        let chatGPTMessage = {
          message: data.choices[0].message.content,
          sender: "ChatGPT",
          direction: "incoming" as MessageDirection,
          position: "normal" as const,
        };
        setMessages([...chatMessages, chatGPTMessage]);
        setTyping(false);
      });
  }

  return (
    <div className="coolBG">
      <div className="chatHeader">
        <button className="specialCloseChatButton" onClick={onClose}>
          X
        </button>
        <p>Here is our friend! ChatGPT!</p>
        {/* <video
          autoPlay
          loop
          muted
          playsInline
          className="mascot"
          src={CalcHappy}
        /> */}
      </div>
      <div className="coolBGeffect">
        <div className="chatContainer">
          <h2 className="exerciseListTitle">🤖 ChatGPT 🤖</h2>
          <MainContainer className="chatMainContainer">
            <ChatContainer>
              <MessageList
                className="chatMessageList"
                scrollBehavior="smooth"
                typingIndicator={
                  typing ? (
                    <TypingIndicator content="ChatGPT is typing..." />
                  ) : null
                }
              >
                {messages.map((message, index) => {
                  return <Message key={index} model={message} />;
                })}
              </MessageList>
              <MessageInput
                placeholder="Type message here..."
                onSend={handleSend}
                onFocus={() => setTypingInChat(true)}
                onBlur={() => setTypingInChat(false)}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </div>
  );
};

export default Chat;
