import { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import { AiOutlineEdit, AiOutlineEnter } from "react-icons/ai";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import { JSONPARSESTRIGFY } from "../../Utils";

type reactopnProps = {
  questionParam: string[];
  className: string;
  modeProp: string;
  onEdit: (question: string, newQuestion: string) => void;
  updateHomework: () => void;
};

const HomeworkComponent = ({
  questionParam,
  modeProp,
  onEdit,
  className,
  updateHomework,
}: reactopnProps) => {
  const [mode, setMode] = useState(modeProp);
  const [state, setState] = useState("");
  const [question, setQuestion] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [questionType, setQuestionType] = useState("firstOrder");
  const [supabase, setSupabase]: any = useState();

  useEffect(() => {
    fetchSupabase();
  }, []);

  const fetchSupabase = async () => {
    const supbaseRes = await fetch("https://knowboo-a95386b70b9e.herokuapp.com/supabase")
      .then((res) => res.json())
      .then((finalRes) => {
        return finalRes;
      });
    const supabaseTemp = createClient(supbaseRes.res.url, supbaseRes.res.key);
    setSupabase(supabaseTemp);
  };

  useEffect(() => {
    if (supabase === undefined) return;
    if (questionParam) {
      setQuestion(questionParam[0]);
      setQuestionType(questionParam[1]);
    }
  }, [supabase]);

  const deleteHomework = async () => {
    // get class studentUID array
    const { data: homeworkData, error: homeworkError } = await supabase
      .from("Classes")
      .select()
      .eq("name", className);
    if (homeworkData) {
      // update class studentUID array
      let homework: string[] = JSON.parse(
        JSON.stringify(homeworkData[0].homework),
      );
      if (homework == null) {
        return;
      }
      homework = homework.filter((q: string) => q !== question);
      const { error: updateError } = await supabase
        .from("Classes")
        .update({ homework: homework })
        .eq("name", className);
      if (updateError) {
        console.error(JSONPARSESTRIGFY(updateError).message);
      }
      updateHomework();
    }
  };

  const updateQuestion = async () => {
    // get class studentUID array
    const { data: homeworkData, error: homeworkError } = await supabase
      .from("Classes")
      .select()
      .eq("name", className);
    if (homeworkData) {
      // update class studentUID array
      const homework = JSONPARSESTRIGFY(homeworkData[0].homework);
      let newHomework;
      if (homework == null) {
        newHomework = [[question, questionType]];
      } else {
        for (let index = 0; index < homework.length; index++) {
          const element = homework[index];
          if (element === question) {
            alert("Homework allready exists");
            return;
          }
        }
        newHomework = [...homework, [question, questionType]];
      }
      const { error: updateError } = await supabase
        .from("Classes")
        .update({ homework: newHomework })
        .eq("name", className);
      if (updateError) {
        console.error(JSONPARSESTRIGFY(updateError).message);
      }
    }
    // await fetchChildData(userID);
    setMode("view");
  };

  return (
    <>
      {mode === "input" ? (
        <div className="studentClass">
          <input
            type="text"
            placeholder="Enter question..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          {/* <label for="questionType">Choose a question type:</label> */}
          <select
            name="questionType"
            id="questionType"
            onChange={(e) => setQuestionType(e.target.value)}
          >
            <option value="firstOrder">First Order</option>
          </select>
          <button onClick={() => updateQuestion()}>Apply</button>
        </div>
      ) : mode === "view" ? (
        <>
          {state === "edit" ? (
            <div className="classInput">
              <input
                type="text"
                placeholder="Edit your new name..."
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
              />
              <button
                className="appButton"
                onClick={() => onEdit(question, newQuestion)}
              >
                Edit
              </button>
            </div>
          ) : (
            <div className="studentComponentContainer">
              <h3>question: {question}</h3>
              <div className="studentComponentContainerButtons">
                <BsTrash className="BsTrash" onClick={() => deleteHomework()} />
                <AiOutlineEdit
                  className="AiOutlineEdit"
                  onClick={() => setState("edit")}
                />
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default HomeworkComponent;
