import { useEffect, useState } from "react";
import { DISPLAYERROR, JSONPARSESTRIGFY } from "../Utils";
import { t } from "../../i18n";
import "./Exercise.css";

type reactopnProps = {
  data: Object;
};

const Exercise = ({ data }: reactopnProps) => {
  const [maxRows, setMaxRows] = useState(0);
  const [stepsString, setStepsString]: any[] = useState([]);
  const [errors, setErrors]: any[] = useState([]);
  const [reactions, setReactions]: any[] = useState([]);
  const [timeStamps, setTimeStamps]: any[] = useState([]);
  const [hints, setHints]: any[] = useState([]);
  const [question, setQuestion] = useState("");
  const [grade, setGrade] = useState("");

  useEffect(() => {
    const JSONDATA = JSONPARSESTRIGFY(data);
    let max = 0;
    if (JSONDATA.stepsString != null && JSONDATA.stepsString.length - 1 > max) {
      max = JSONDATA.stepsString.length - 1;
    }
    if (JSONDATA.errors != null && JSONDATA.errors.length > max) {
      max = JSONDATA.errors.length;
    }
    if (JSONDATA.reactions != null && JSONDATA.reactions.length > max) {
      max = JSONDATA.reactions.length;
    }
    if (JSONDATA.timeStamps != null && JSONDATA.timeStamps.length - 1 > max) {
      max = JSONDATA.timeStamps.length - 1;
    }
    if (JSONDATA.hints != null && JSONDATA.hints.length > max) {
      max = JSONDATA.hints.length;
    }
    setMaxRows(max);
    //--------------------------------------------------------------------------
    if (JSONDATA.stepsString != null) {
      // let newStepsString: string[] = JSONDATA.stepsString;
      // if (newStepsString.length > 1) {
      //   newStepsString.shift();
      // }
      // if (newStepsString == null) {
        setStepsString(JSONDATA.stepsString);
      // } else {
      //   setStepsString(newStepsString);
      // }
    }
    if (JSONDATA.timeStamps != null) {
      // let newTimeStamps: string[] = JSONDATA.timeStamps;
      // if (newTimeStamps.length > 1) {
      //   newTimeStamps.shift();
      // }
      // if (newTimeStamps == null) {
        setTimeStamps(JSONDATA.timeStamps);
      // } else {
      //   setTimeStamps(newTimeStamps);
      // }
    }
    //--------------------------------------------------------------------------
    setErrors(translate(JSONDATA.errors));
    setReactions(translate(JSONDATA.reactions));
    setHints(translate(JSONDATA.hints));
    setQuestion(JSONDATA.question);
    setGrade(JSONDATA.grade);
  }, []);

  const translate = (arr: string[]) => {
    let temp = [];
    for (let index = 0; index < arr.length; index++) {
      let element = arr[index];
      try {
        element = t(element[0]);
      } catch(e: any) {
        DISPLAYERROR(e);
      }
      temp.push(element);
    }
    return temp;
  };

  return (
    <div className="exercise">
      <h3>Exercise to solve: {question}</h3>
      <h3>Grade: {grade}</h3>
      <div className="exerciseContainer">
        <table>
          <tbody>
            <tr>
              <th>Steps</th>
            </tr>
            {stepsString &&
              stepsString.map((data: string, index: number) => (
                <tr key={index}>
                  <td>{data}</td>
                </tr>
              ))}
            {stepsString &&
              stepsString.length < maxRows &&
              [...Array(maxRows - stepsString.length).keys()].map(
                (_, index: number) => (
                  <tr key={index}>
                  <td>{" "}</td>
                  </tr>
                ),
              )}
            {stepsString == null &&
              [...Array(maxRows).keys()].map((_, index: number) => (
                <tr key={index}>
                  <td>{" "}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* ---------------------------------------------------------- */}
        <table>
          <tbody>
            <tr>
              <th>Errors</th>
            </tr>
            {errors &&
              errors.map((data: string, index: number) => (
                <tr key={index}>
                  <td>{data}</td>
                </tr>
              ))}
            {errors &&
              errors.length < maxRows &&
              [...Array(maxRows - errors.length).keys()].map(
                (_, index: number) => (
                  <tr key={index}>
                  <td>{" "}</td>
                  </tr>
                ),
              )}
            {errors == null &&
              [...Array(maxRows).keys()].map((_, index: number) => (
                <tr key={index}>
                  <td>{" "}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* -------------------------------------------------------- */}
        <table>
          <tbody>
            <tr>
              <th>Hints</th>
            </tr>
            {hints &&
              hints.map((data: string, index: number) => (
                <tr key={index}>
                  <td>{data}</td>
                </tr>
              ))}
            {hints &&
              hints.length < maxRows &&
              [...Array(maxRows - hints.length).keys()].map(
                (_, index: number) => (
                  <tr key={index}>
                  <td>{" "}</td>
                  </tr>
                ),
              )}
            {hints == null &&
              [...Array(maxRows).keys()].map((_, index: number) => (
                <tr key={index}>
                  <td>{" "}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* -------------------------------------------------------- */}
        <table>
          <tbody>
            <tr>
              <th>Reactions</th>
            </tr>
            {reactions &&
              reactions.map((data: string, index: number) => (
                <tr key={index}>
                  <td>{data}</td>
                </tr>
              ))}

            {reactions != null &&
              reactions.length < maxRows &&
              [...Array(maxRows - reactions.length).keys()].map(
                (_, index: number) => (
                  <tr key={index}>
                  <td>{" "}</td>
                  </tr>
                ),
              )}
            {reactions == null &&
              [...Array(maxRows).keys()].map((_, index: number) => (
                <tr key={index}>
                  <td>{" "}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* -------------------------------------------------------- */}
        <table>
          <tbody>
            <tr>
              <th>Time stamps</th>
            </tr>
            {timeStamps &&
              timeStamps.map((data: string, index: number) => (
                <tr key={index}>
                  <td>{data}</td>
                </tr>
              ))}
            {timeStamps != null &&
              timeStamps.length < maxRows &&
              [...Array(maxRows - timeStamps.length).keys()].map(
                (_, index: number) => (
                  <tr key={index}>
                  <td>{" "}</td>
                  </tr>
                ),
              )}
            {timeStamps == null &&
              [...Array(maxRows).keys()].map((_, index: number) => (
                <tr key={index}>
                  <td>{" "}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Exercise;
