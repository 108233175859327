import Logo from '../../Logo_no_beckround.png';
import './SignUpComponent.css';

function SignUpComponent() {
  return (
    <div className='coolBGSignUpComponent'>
      <div className='coolBGeffectSignUpComponent'>
        <form id="signUpDemoForm" className='innerContainer' action="https://formsubmit.co/contact@knowboo.com" method="POST">
          <img className="my-4 h-28 w-auto justify-self-center" src={Logo} alt="logo" />
          <h2 className='h2Text'>The demo is over! but you can Sign Up for the full version!</h2>
          <label htmlFor="email">✉️Email✉️</label>
          <input id="email" className='accountInput' type="email" placeholder='Enter your email here...'/>
          <label htmlFor="fullName">Full name🙋</label>
          <input id="fullName" className='accountInput' type="text" placeholder='Enter your first name here...' />
          <button className='appButton mt-5' type='submit'>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default SignUpComponent;
