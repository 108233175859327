import './settings.css';

function BugReport(props) {
    return (
        <form id="bugReportForm" className='bugReportForm' action="https://formsubmit.co/contact@knowboo.com" method="POST">
            <div className='settingsHeader'>
                <h3>Bug Report</h3>
                <button className='closeButton appButton' onClick={() => props.close()}>X</button>
            </div>
            <label htmlFor="name">🙋Name🙋</label>
            <input type="text" name="name" id="name" placeholder='Enter your name here...' required />
            <label htmlFor="email">✉️Email✉️</label>
            <input type="email" name="email" id="email" placeholder='Enter your email here...' required />
            <label htmlFor="tel">📱Phone📱</label>
            <input type="tel" name="tel" id="tel" placeholder='Enter your phone here...' required />
            <label htmlFor="msg">Message</label>
            <textarea rows="4" cols="50" name="msg" id="msg" placeholder='Explain the bug...' required />
            <button className="appButton" type='submit'>Submit</button>
        </form>
    )
}

export default BugReport;