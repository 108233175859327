import { createRoot } from "react-dom/client";
import HomePage from "./Added/Home/HomePage";
import "./excalidraw-app/pwa";
import "./excalidraw-app/sentry";

window.__EXCALIDRAW_SHA__ = process.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  <HomePage/>,
);
